import pharmacyIcon from 'assets/images/pharmacyIcon.png'
import categoryIcon from 'assets/images/categoryIcon.png'
const MenuList = {

    "superAdmin": [{
        "title": 'leftMenu.dashboard',
        "url": "/dashboard",
        "icon": "dasbhoard",
        "top": '4px',
        "permissionKey": "dashboard",
        "key" : "dashboard"
    },
    
    {
        "title": 'leftMenu.user',
        "url": "/user",
        "icon": 'wall',
        "top": '4px',
        "permissionKey": "user",
        "key" : "user"
    },
    {
        "title": 'leftMenu.pharmacy',
        "url": "/pharmacy",
        'img': pharmacyIcon,
        'width': '18px',
        'height' : '22px',
        "top": '4px',
        "permissionKey": "tasks",
        "key" : "pharmacy"
    },
    {
        "title": "leftMenu.product",
        "icon": "feedback",
        "permissionKey":"products",
        "key":"product",
        "subMenu": [{
            "title": "leftMenu.product.allProduct",
            "url": "/product/approvedProduct",
            "icon": "",
            "key": "product/approvedProduct",
        },
        {
            "title": "leftMenu.product.pendingProduct",
            "url": "/product/pendingProduct",
            "icon": "",
            "key": "product/pendingProduct",
        }
        ]
    },
    {
        "title": 'leftMenu.order',
        "url": "/order",
        "icon": "product-list",
        "top": '4px',
        "permissionKey": "order",
        "key" : "order"
    },
    {
        "title": 'leftMenu.category',
        "url": "/category",
        'img' : categoryIcon,
        'width': '18px',
        'height' : '12px',
        "top": '4px',
        "permissionKey": "tasks",
        "key" : "category"
    },
    {
        "title": 'leftMenu.setting',
        "url": "/settings",
        "icon": "setting",
        "top": '4px',
        "permissionKey": "tasks",
        "key" : "settings"
    },
    

    ],
}

export default MenuList;