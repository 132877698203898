import React, { useEffect,useState } from "react";
import Widget from "components/Widget"
import { Timeline, Empty } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { DataGetAction } from 'redux/actions/http';
import IntlMessages from 'util/IntlMessages'
const Index = ({ recentActivity, showImage ,title}) => {

    const dispatch = useDispatch();
    const activityProps = useSelector(state => state.activities);
    const [limit, setLimit] = useState(15)

    const { activitiesList } = activityProps;
    const fetchRecentActivity = () => {
        dispatch(DataGetAction('getRecentActivity', '', 'gettingActivity'));
    }

    useEffect(fetchRecentActivity, []);

    const renderNoDate = () => {
        return <Empty style={{ marginTop: '30px' }} description={ <IntlMessages id="dashboard.noData" />} />
    }
    const onLoadMore =()=> {
        setLimit(activitiesList && activitiesList.length)
      };
    return (
        <Widget title={<h4 className="gx-blue-font gx-font-sans-bold gx-mb-0 gx-text-capitalize">{title}</h4>} >
            <div className="gx-timeline-info">
                {activitiesList && activitiesList.length > 0 ? <Timeline>
                    {showImage && activitiesList.slice(0,limit).map(item => {
                        return <Timeline.Item className="gx-d-flex" mode="alternate" dot={

                            <span className="ant-avatar gx-size-40 ant-avatar-circle ant-avatar-image">
                                <img src={item.url} alt="" />
                            </span>}>
                            {item.desc}
                        </Timeline.Item>
                    })}

                    {!showImage && activitiesList.slice(0,limit).map(item => {
                        return <Timeline.Item className="gx-d-flex" mode="alternate">
                            {item.desc}
                        </Timeline.Item>
                    })}
                </Timeline> : renderNoDate()}
             {activitiesList && activitiesList.length > 15 &&   <span className="gx-link gx-btn-link" onClick={onLoadMore}>Load More</span>}
            </div>
                    

        </Widget>
    )
}

export default Index
