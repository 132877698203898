
const initialState = {
    formData: {},
    allCategories: [],
    alreadyExist: false,
    textMessage: '',
    cardLink: '',
    isAddressExist: false,
    arrayTempLinkSpinner: false,
    arrayTempLink_success: false,
    arrayTempLink_error: false,
    vitaleModal: false,
    initialRecord: null,
    insuranceModal: false,
    prescriptionDocuments : []
}

export default (state = initialState, action) => {
    switch (action.type) {

        case 'set_Form_Data': {
            let obj = {}
            obj[action.payload.key] = action.payload.value
            return {
                ...state,
                formData: { ...state.formData, ...obj }
            }
        }
        case 'reset_Form_Data': {
            return {
                ...state,
                formData: {}
            }
        }
        case 'getCategory_SUCCESS': {
            return {
                ...state,
                allCategories: action.payload.Record
            }
        }
        case 'emailCheck_SUCCESS': {
            return { ...state, alreadyExist: action.payload.Record }
        }
        case 'reset_alreadyExist': {
            return { ...state, alreadyExist: false }
        }
        case 'emailCheck_FAILURE': {
            return { ...state, textMessage: action.payload }
        }
        case 'StartLoader_START': {
            return { ...state, startLoader: true }
        }
        case 'Toggle_Image_Modal': {
            return { ...state, vitaleModal: !state.vitaleModal, initialRecord: action.payload ? action.payload : null }
        }
        case 'Toggle_Insurance_Modal': {
            return { ...state, insuranceModal: !state.insuranceModal, initialRecord: action.payload ? action.payload : null }
        }
        case 'gettingTempLink_START': {
            return {
                ...state,
                tempLinkSpinner: true
            }
        }
        case 'getTempLink_SUCCESS': {
            return { ...state, cardLink: action.payload.Record, startLoader: false, tempLinkSpinner: false }
        }

        case 'getTempLink_FAILURE': {
            return { ...state, startLoader: false, tempLinkSpinner: false }
        }
        case 'addressCheck_SUCCESS': {

            return { ...state, isAddressExist: action.payload.Record }
        }
        case 'reset_addressExist': {
            return { ...state, isAddressExist: false }
        }
        case 'addressCheck_FAILURE': {
            return { ...state, textMessage: action.payload }
        }
        case 'gettingArrayTempLink_START': {
            return {
                ...state,
                arrayTempLinkSpinner: true
            }
        }
        case 'getArrayTempLink_SUCCESS': {
            const productImagesWithType = getPrescriptionDocuments(action.payload.Record)
            return { ...state, productImages: action.payload.Record,prescriptionDocuments : [...productImagesWithType], arrayTempLinkSpinner: false, arrayTempLink_success: true }
        }

        case 'getArrayTempLink_FAILURE': {
            return { ...state, startLoader: false, arrayTempLinkSpinner: false, arrayTempLink_error: true }
        }
        default:
            return state
    }
}
const getPrescriptionDocuments = (record) => {
    let newRecord = []
    if (record && record.length > 0) {
        record.forEach(item => {
            if (item.includes('.pdf')) {
                let obj = {}
                obj.file = item
                obj.type = 'pdf'
                newRecord.push(obj)
            }
            else {
                let obj1 = {}
                obj1.file = item
                obj1.type = 'image'
                newRecord.push(obj1)
            }
        })
    }
    return newRecord


}