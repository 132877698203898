import React from "react";
import { Button } from 'antd';
import Widget from "components/Widget";
import NewOrderList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import DateFilter from 'components/Filters/DateFilter'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { useDispatch, useSelector } from 'react-redux'
import AddNewOrderModal from './components/AddNewOrderModal'
import CustomerDetailModal from './components/CustomerDetailModal'
import { renderDate, renderStatus ,camelCaseToNormal} from 'utils/commonUseFunction'
import TableAction from 'components/Table/Actions'
import { ReloadOutlined } from '@ant-design/icons';
import { DataGetAction } from 'redux/actions/CommonHttp'
import SelectFilter from 'components/Filters/SelectFilter'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
import PrescriptionModal from './components/PrescriptionModal'
const Index = () => {

    const dispatch = useDispatch()

    const CurdProps = useSelector(state => state.CrudR)
    const { Loader } = CurdProps;
    const locale = useSelector(({ settings }) => settings.locale);
    const { languageId } = locale
    console.log('=======locale=======', locale)

    const openCustomerDetailModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Customer_Detail_Modal"
        })
    }


    const columns = [
        {
            title: <IntlMessages id="Order.no" />,
            render: (record) => <span >{record.orderId}</span>,
            key: "No.",
            width : 50
        },
        
        {
            title: <IntlMessages id="Order.customerName" />,
            render: (record) => <span className="gx-link gx-text-capitalize" onClick={() => { openCustomerDetailModal(record) }}>{record.fullName}</span>,
            key: "customerName",
            width : 120
        },
        {
            title: <IntlMessages id="Order.address" />,
            render: (record) => <span>{record.address}</span>,
            key: "address",
            width : 180
        },

        {
            title: <IntlMessages id="Order.dateAndTime" />,
            render: (record) => <span>{renderDate(record.createdAt)}</span>,
            key: "date",
            width : 120
        },
        
        {
            title: <IntlMessages id="Order.deliveryMode" />,
            render: (record) => record.deliveryMode === 'rider' ? <span className="gx-text-primary gx-d-flex gx-flex-column">
                <span>{camelCaseToNormal(record.deliveryMode)  }</span>
                
                <span className="gx-ml-1">{ renderDate(record.scheduleTime,'MMMM Do YYYY')}</span>
                <span className="gx-ml-1">{ renderDate(record.scheduleTime,'hh:mm:ss a')}</span>
            </span> :<span className="gx-text-primary">{camelCaseToNormal(record.deliveryMode)}</span> ,
            key: "status",
        },
        {
            title: <IntlMessages id="Order.orderFor" />,
            render: (record) => <span>{camelCaseToNormal(record.orderFor ? record.orderFor : 'Self' )}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="Order.serviceUsed" />,
            render: (record) => <span>{camelCaseToNormal(record.serviceUse ? record.serviceUse : '---' )}</span>,
            key: "date"
        },
        {
            title: <IntlMessages id="Order.status" />,
            render: (record) => <span className="gx-link">{renderStatus(record.status, languageId)}</span>,
            key: "view"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },

    ];

    const openNewOrderModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_NewOrder_Modal"
        })
    }
    const openPrescriptionModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Add_Prescription_Modal"
        })
    }

    const renderActions = (record) => {

        return <div className="gx-d-flex gx-flex-column">
          
         { record.orderType === 'nonPrescription' && <span className="gx-link" onClick={() => { openPrescriptionModal(record) }}><IntlMessages id="Order.view" /></span>}
         { record.orderType === 'prescription' && <span className="gx-link" onClick={() => { openNewOrderModal(record) }}><IntlMessages id="Order.view" /></span>}
            {record.status === 'newOrder' &&  <DeleteConfirm
                type="cancelOrder"
                recordName={'orderList'}
                method="PUT"
                selectedItem={record}
                apiRequest="DeleteRecord"
                buttonName="Yes"
                cancelText={intlPlaceholder('confirmationModal.cancel')}
                oKText={intlPlaceholder('confirmationModal.yes')}
                title={intlPlaceholder('Order.actions.cancel_text')}
                content={intlPlaceholder('Order.actions.cancel_content')}
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="Order.actions.cancel" /></span>}
            />}
        </div>
    }

    const reload = () => {
        dispatch(DataGetAction('getAllRecord', 'FetchRecord', { query: 'all' }, 'StartSpinner', '', 'orderList'))
    }

    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="Order.pageTitle" />
                    </h4>
                }
                
            >
  <div className="gx-d-flex gx-justify-content-end">
                    
                        <div style={{ width: '220px', marginRight: '12px' }}>
                            <DateFilter placeholder={[intlPlaceholder('Order.filter.startDate'), intlPlaceholder('Order.filter.endDate')]} apiName="getAllRecord" requestType="FetchRecord" recordName="orderList" />
                        </div>
                        <div style={{ height: '100%', marginRight: '12px' }} >
                            <SelectFilter
                                isShowingAll={true}
                                className="select-filter"
                                apiName="getAllRecord"
                                searchKey="status"
                                query="status"
                                recordName="orderList"
                                options={[
                                    { status: "newOrder", label: <IntlMessages id="Order.filter.searchByStatus.newOrder" /> },
                                    { status: "quotationSent", "label": <IntlMessages id="Order.filter.searchByStatus.quotationSent" /> },
                                    { status: "riderPicked", "label": <IntlMessages id="Order.filter.searchByStatus.riderPicked" /> },
                                    { status: "completed", "label": <IntlMessages id="Order.filter.searchByStatus.completed" /> },
                                    { status: "cancelled", "label": <IntlMessages id="Order.filter.searchByStatus.cancelled" /> },

                                ]} placeholder={<IntlMessages id="Order.filter.searchByStatus" />} ItemValue="status" label="label" />
                        </div>
                        <div style={{ width: '200px', marginRight: '12px' }}>
                            <SearchBox recordName="orderList" placeholder={intlPlaceholder('Order.filter.productNameOrderId')} apiName="getAllRecord" requestType="FetchRecord" />
                        </div>
                        <div style={{ width: '120px', marginRight: '12px' }}>
                            <SearchBox
                                recordName="orderList"
                                placeholder={intlPlaceholder('Order.filter.OrderId')}
                                apiName="getAllRecord"
                                requestType="FetchRecord"
                                customQuery={{ query: 'orderId' }}
                            />
                        </div>
                        <div>
                            <Button type="primary" className="gx-pt-0" onClick={() => { reload() }} icon={<ReloadOutlined spin={Loader ? true : false} />} />
                        </div>
                    </div>
                <NewOrderList recordName="orderList" apiName="getAllRecord" pagination={{
                    showSizeChanger: true
                }} columns={columns} />
            </Widget>
            <AddNewOrderModal />
            <PrescriptionModal />
            <CustomerDetailModal />
        </Layout>
    );
};

export default Index;