import React,{useState,useEffect} from 'react'
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import FormModal from "components/Modal/FormModal";
import IntlMessages from 'util/IntlMessages'
import ProductForm from './ProductForm'


const AddNewProductForm = () => {



    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { Add_Product_Modal, Loader } = curdProps

    
    let initialState = {
        loading: false,
        imageUrl: '',
    }
    const [photoList, setPhotoState] = useState(initialState)
    const reset = () => {

        setPhotoState({
            loading: false,
            imageUrl: '',
        })
    }

    useEffect(reset, [Add_Product_Modal])
    return (
        <div>
            <Button
                className='gx-pt-0'
                type="primary"
                onClick={() => {
                    dispatch({
                        type: 'Show_Modal',
                        payload: null,
                        ModalName: "Add_Product_Modal"
                    })
                }}
            >
                <IntlMessages id="pharmacy.addNewButton.label" />
            </Button>
            <FormModal
                modalTitle={<p className="gx-text-primary"> <IntlMessages id="product.addProductModal.title" /> </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="product.modal.save" />}
                cancelButtonText={<IntlMessages id="product.modal.cancel" />}
                apiMethod="POST"
                addApiName="addProduct"
                addApiRequest="AddRecord"
                apiLoader="StartSpinner"
                ModalName="Add_Product_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Add_Product_Modal ? Add_Product_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                width={600}
                className="doctor-profile-modal"
                extraFieldName="productImageUrl"
                extraFieldValue={photoList.imageUrl}
                recordName="pendingProductList"
            >
                <ProductForm setPhotoState={setPhotoState} photoList={photoList} />
            </FormModal>
        </div>

    )
}

export default AddNewProductForm
