//generalAnalytics index
import React, { useEffect } from 'react'

import ChartCard from 'components/ChartCard'
import RevenueOverview from './components/RevenueOverview'
import { Col, message, Row,Spin } from 'antd'

import MonthlyAnalysis from 'components/AreaChart'
import TotalSales from './components/TotalSales'
import RecentPharmacies from './components/RecentPharmacies'
import RecentActivities from 'components/ActivitiesList'
import { DataGetAction } from 'redux/actions/CommonHttp'
import { useDispatch, useSelector } from 'react-redux'
import IntlMessages from "util/IntlMessages"
const Index = () => {

    const dispatch = useDispatch();
    const adminProps = useSelector(state => state.adminDashboard)
    const { dashboardData,fetchingLoader,monthlyAnalysis,textMessage,error } = adminProps

    const fetchAdminDashboard = () => {
        dispatch(DataGetAction('getDashboardOverview','getDashboardOverview', '', 'gettingAdminDashboard'));
        dispatch(DataGetAction('getMonthlyOverview', 'getMonthlyOverview','', 'getMonthlyOverview'));
        
        
    }
    useEffect(fetchAdminDashboard, [])


    const Data = [
        { name: 'Page A', price: 200 },
        { name: 'Page B', price: 1200 },
        { name: 'Page C', price: 600 },
        { name: 'Page D', price: 1600 },
        { name: 'Page D', price: 1000 },
        { name: 'Page H', price: 2260 },
        { name: 'Page K', price: 800 },
    ];
    // const analysis = [
    //     { name: '', pharmacy: 200,  orders : 200 , cancelled : 100, delivered : 180 },
    //     { name: 'JAN', pharmacy: 400 , orders : 200 , cancelled : 100, delivered : 180 },
    //     { name: 'FEB', pharmacy: 150 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'MAR', pharmacy: 400 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'APR', pharmacy: 1000 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'MAY', pharmacy: 400 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'JUN', pharmacy: 1200, orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'JUL', pharmacy: 1000, orders : 200 , cancelled : 100, delivered : 180 },
    //     { name: 'AUG', pharmacy: 800, orders : 200 , cancelled : 100, delivered : 180 },
    //     { name: 'SEP', pharmacy: 750 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'OCT', pharmacy: 1500 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: 'NOV', pharmacy: 1000, orders : 200 , cancelled : 100, delivered : 180 },
    //     { name: 'DEC', pharmacy: 1500 , orders : 200 , cancelled : 100, delivered : 180},
    //     { name: '', pharmacy: 500 , orders : 200 , cancelled : 100, delivered : 180},
    //   ];

    // const inspectionData = [
    //     { name: '', 'TNew Leads': 200, 'valid': 10, 'Invalid': 10, 'Revenue': 10 },
    //     { name: 'Monday', 'TNew Leads': 400, 'valid': 12, 'Invalid': 10, 'Revenue': 10 },
    //     { name: 'Tuesday', 'TNew Leads': 150, 'valid': 15, 'Invalid': 10, 'Revenue': 10 },
    //     { name: 'Wednesday', 'TNew Leads': 400, 'valid': 1, 'Invalid': 10, 'Revenue': 10 },
    //     { name: 'Thursday', 'TNew Leads': 1000, 'valid': 23, 'Invalid': 10, 'Revenue': 10 },
    //     { name: 'Friday', 'TNew Leads': 500, 'valid': 34, 'Invalid': 11, 'Revenue': 6 },
    //     { name: 'Saturday', 'TNew Leads': 400, 'valid': 7, 'Invalid': 10, 'Revenue': 10 },
    //     { name: 'Sunday', 'TNew Leads': 1200, 'valid': 34, 'Invalid': 10, 'Revenue': 10 },
    //     { name: '', 'TNew Leads': 70, 'valid': 90, 'Invalid': 10, 'Revenue': 10 },
    // ];

    const recentActivities = [
        {
            id: 1,
            name: 'Mila Alba',
            desc: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corrupti, quae?',
            url: "https://picsum.photos/50",
        },
        {
            id: 2,
            name: 'Bob Builder',
            desc: 'Lorem ipsum dolor sit amet.',
            url: "https://picsum.photos/50",
        },
        {
            id: 3,
            name: 'Tom Moody',
            desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            url: "https://picsum.photos/50",
        },
        {
            id: 4,
            name: 'Norman Dolphi',
            desc: 'Norman Dolphi is looking for a house in New Jersy, USA',
            url: 'https://picsum.photos/50',
        },
        {
            id: 5,
            name: 'Bob Builder',
            desc: 'Lorem ipsum dolor sit amet.',
            url: "https://picsum.photos/50",
        },
        {
            id: 6,
            name: 'Tom Moody',
            desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            url: "https://picsum.photos/50",
        },
        {
            id: 7,
            name: 'Norman Dolphi',
            desc: 'Norman Dolphi is looking for a house in New Jersy, USA',
            url: 'https://picsum.photos/50',
        }
    ];

    if(error){
        message.error(textMessage)
    }


    return (
        <>

{fetchingLoader ?
                <Spin size="large" color="blue" />
                : 
    <>
            <Row >
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                    
                    <ChartCard count={(dashboardData.length && dashboardData[0].totalPharmacy) || 0} desc={<IntlMessages id="dashboard.noOfPharmacies" />} Data={Data} bgColor={'#FCEAF4'} borderColor={'#F8C8E3'} />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                    <ChartCard count={(dashboardData.length &&dashboardData[0].totalProduct) || 0} desc={<IntlMessages id="dashboard.noOfProducts" />} Data={Data} bgColor={'#E3F7D7'} borderColor={'#B8E986'} />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                    <ChartCard count={(dashboardData.length &&dashboardData[0].totalRegisterUser) || 0} desc={<IntlMessages id="dashboard.registeredUser" />} Data={Data} bgColor={'#F3ECF9'} borderColor={'#C39FDF'} />
                </Col>
                <Col xl={6} lg={6} md={12} sm={24} xs={24}>
                    <ChartCard count={(dashboardData.length &&dashboardData[0].orderCompleted) || 0} desc={<IntlMessages id="dashboard.noOfCompletedOrders" />} Data={Data} bgColor={'#e5eef5'} borderColor={'#6aafe6'} />
                </Col>
            </Row>

            <Row>
                <Col xl={17} lg={17} md={12} sm={24} xs={24}>
                    <Row>
                        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                            <RevenueOverview noOfClients={12} noOfSites={5} dashboardData={dashboardData} />
                        </Col>
                        <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                            <TotalSales dashboardData={dashboardData}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <RecentPharmacies />
                        </Col>
                    </Row>


                    
                    
                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                           
                            <MonthlyAnalysis data={monthlyAnalysis} title={<IntlMessages id="dashboard.monthlyAnalysis" />} width={'100%'} height={180} axisX={'name'} axisY={'totalOrder'}
                                    showNumber={4}
                                    showYAxis={true} startColor={'#ffffff'} endcolor={'#51BDA7'} strokeColor={'#A2E2D9'} />
                        </Col>
                    </Row>
                </Col>
                <Col xl={7} lg={7} md={12} sm={24} xs={24}>
                    <div className="recent-activity-container">
                        <RecentActivities recentActivity={recentActivities} showImage={false} title={<IntlMessages id="dashboard.notifications" />} />
                    </div>
                </Col>
            </Row>
        </>
}
       </>
    )
}

export default Index
