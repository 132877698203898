import React from 'react'
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'

const EditProfile = () => {



    const curdProps = useSelector(state => state.CrudR)
    const { Customer_Detail_Modal, initialValues } = curdProps

    console.log('==========initialValues========', initialValues)

    const renderRow = (label, value) => {
        return <div class="gx-d-flex gx-justify-content-between gx-mb-2">
            <span style={{ width: '35%' }} ><IntlMessages id={label} /></span>
            <span style={{ width: '65%' }} >{value}</span>
        </div>
    }


    return (
        <div>


            <SimpleModal
                width={500}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">
                    {<IntlMessages id="Order.addNewOrderModal.customerDetail" />}

                </span>}
                modalFor="Customer_Detail_Modal"
                visible={Customer_Detail_Modal ? Customer_Detail_Modal : false}>


                <div class="gx-d-flex gx-justify-content-between gx-mb-2">
                    <span style={{ width: '35%' }} ><IntlMessages id={"Order.pharmacyName"} /></span>
                    <span style={{ width: '65%',textTransform : 'capitalize' }} >{initialValues && initialValues.pharmacyName}</span>
                </div>
                {renderRow('Order.phone', initialValues && initialValues.phoneNumber)}
                {renderRow('Order.email', initialValues && initialValues.email)}



            </SimpleModal>
        </div>
    )
}

export default EditProfile
