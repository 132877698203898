import store from '../store';

export const SocketResponseState = (type, payload,recordName) => {

    
    store.dispatch({
        type: `${type}_SUCCESS`,
        payload: payload,
        recordName : recordName,
    });

}
