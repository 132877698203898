import React from 'react'
import { DatePicker } from "antd";
import { useDispatch } from 'react-redux'
import { DataGetAction } from 'redux/actions/CommonHttp';
const { RangePicker } = DatePicker
const DateFilter = ({apiName,requestType,recordName,placeholder}) => {
    
    const dispatch = useDispatch()
    const onChange = (dates, dateStrings) => {
        if(dates){
            dispatch(DataGetAction(apiName, requestType, { query: "search", key: "date", startDate: dates[0], endDate: dates[1] }, '','',recordName));
        }
        else{
            dispatch(DataGetAction(apiName, requestType, { query: "all"}, '','',recordName));
        }
        
    }

    return (
        
            <RangePicker onChange={onChange} placeholder={placeholder} />
        
    )
}

export default DateFilter
