import React from "react";
import { Route, Switch } from "react-router-dom";
import RestPassword from './Auth/restPassword'
import Category from'./Admin/Category'
import User from './Admin/User'
import Pharmacy from './Admin/Pharmacy'
import AdminDashboard from './Admin'
import Settings from './Admin/Setting'
import Order from './Admin/Order'
import PendingProduct from './Admin/Product/PendingProduct'
import ApprovedProduct from './Admin/Product/ApprovedProduct'
//import User from "cotter/lib/models/User";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {/* <Route path={`${match.url}home`} component={Home}/> */}

      <Route exact path={`${match.url}dashboard`} component={AdminDashboard} />
      <Route path={`${match.url}resetPassword`} component={RestPassword} />
      <Route path={`${match.url}admin/resetPassword`} component={RestPassword} />
      <Route path={`${match.url}admin/resetPassword`} component={RestPassword} />
      <Route path={`${match.url}product/pendingProduct`} component={PendingProduct} />
      <Route path={`${match.url}product/approvedProduct`} component={ApprovedProduct} />
      <Route path={`${match.url}user`} component={User} />
      <Route path={`${match.url}pharmacy`} component={Pharmacy} />
      <Route path={`${match.url}order`} component={Order} />
      <Route path={`${match.url}category`} component={Category} />
      <Route exact path={`${match.url}settings`} component={Settings} />
    </Switch>
  </div>
);

export default App;
