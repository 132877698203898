import React,{useEffect} from "react";
import Widget from "components/Widget";
import PendingProductList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SelectFilter from 'components/Filters/SelectFilter'
import SearchBox from 'components/Filters/SearchBox'
import AddNewProductForm from './components/AddNewProductForm'
import { useDispatch,useSelector } from 'react-redux'
import EditProduct from './components/EditProduct'
import { intlPlaceholder } from 'util/intlPlaceholder'
import { DataRequestAction,DataGetAction } from 'redux/actions/CommonHttp';
import { renderStatus } from 'utils/commonUseFunction'
import dummy from 'assets/images/user-dummy.png'
import moment from 'moment'
import PharmacyNameModal from "../PharmacyNameModal";
import UploadBulk from './components/UploadBulk'
const Index = () => {

 
    const dispatch = useDispatch()
    const fetchList = () => {

        dispatch(DataGetAction('getCategory', 'getCategory', { query: 'all' }, '', '', 'allCategories'))

    }

    useEffect(fetchList, [])


    const pageProps = useSelector(state => state.pageInfo)
    const { allCategories } = pageProps


    const renderPharmacyNames = (pharmacyNames) => {
        let pharmacies =
          pharmacyNames &&
          pharmacyNames.length > 0 &&
          pharmacyNames.map((item) => item.pharmacyName);
        return (
          <div>
            <p >{pharmacies && pharmacies.length > 0 && pharmacies[0]}</p>
            {pharmacies && pharmacies.length > 1 && <p style={{color : 'blue', cursor : 'pointer'}}
            onClick={()=>{
                dispatch({
                    type: "Show_Modal",
                    payload: pharmacies,
                    ModalName: "Pharmacy_Name_Modal",
                  });
            }}
            >+ {pharmacies.length -1}  more</p>}
          </div>
        );
      };
    const columns = [
        {
            title: <IntlMessages id="product.id" />,
            render: (record) => <span>{record.productId}</span>,
            key: "id",
        },
        
        {
            title: <IntlMessages id="product.productName" />,
            render: (record) => {
                return <div className="gx-d-flex gx-align-items-center">
                    {record.productImageUrl ? <img alt="" src={record.productImageUrl} width='30' style={{ borderRadius: '50%' }} height='30' /> :<img src={dummy} width='30' style={{ borderRadius: '50%' }} height='30' alt="productImage" />}
                    <span className="gx-ml-2">{record.productName && record.productName }</span> </div>
            },
            key: "doctorName",
        },

        {
            title: <IntlMessages id="product.category" />,
            render: (record) => <span>{record.categoryName}</span>,
            key: "category"
        },
        {
            title: <IntlMessages id="product.manufacturer" />,
            render: (record) => <span>{record.manufacturer}</span>,
            key: "manufacturer"
        },
        {
            title: <IntlMessages id="product.pharmacyName" />,
            render: (record) => renderPharmacyNames(record.pharmacyInfo),
            key: "pharmacyName"
        },
        {
            title: <IntlMessages id="product.price" />,
            render: (record) => <span>€{record.price && record.price} </span>,
            key: "price"
        },
        {
            title: <IntlMessages id="product.status" />,
            render: (record) => <span className="gx-text-capitalize">{renderStatus(record.status)} </span>,
            key: "location"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },
    ];
    const changeUserStatus = (_id, status) => {
        dispatch(DataRequestAction('PUT', 'updateProductStatus', 'DeleteRecord', { _id: _id, status: status }, 'updatingUserStatus', false,'pendingProductList'));
    }

    


    const renderEdit = (record) =>{
        record.dateAndTime = moment(record.dateAndTime)
        dispatch({

            type: 'Show_Modal',
            payload: record,
            ModalName: "Edit_Product_Modal"
        })
   
    }
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            <span className="action-link first gx-link" onClick={() => renderEdit(record)}><IntlMessages id="pendingProduct.actions.edit" /></span>
            {(record.status === "pending") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'approved')}><IntlMessages id="pendingProduct.actions.approve" /></span>}
            {(record.status === "pending") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'reject')}><IntlMessages id="pendingProduct.actions.reject" /></span>}
        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold gx-mb-3"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="pendingProduct.pageTitle" />
                    </h4>
                }
                extra={<div className="gx-d-flex gx-mb-0" style={{ flexWrap: 'wrap', gap: '10px' }}>
                    {/* <div style={{ width: '240px', marginRight: '12px' }}>
                                <DateFilter placeholder={[intlPlaceholder('pendingProduct.filter.startDate'),intlPlaceholder('pendingProduct.filter.endDate')]}  apiName="getPendingProduct" requestType="FetchRecord" recordName="pendingProductList" />
                            </div> */}
                    <div style={{ height: '100%' }} >
                                <SelectFilter
                                    isShowingAll={true}
                                    className="select-filter"
                                    apiName="getPendingProduct"
                                    searchKey="category"
                                    recordName="pendingProductList"
                                    options={allCategories} placeholder={<IntlMessages id="pendingProduct.filter.filterByCategory" />} ItemValue="_id" label="name" />
                            </div>
                    <div style={{ width: '220px' }}>
                        <SearchBox  placeholder={intlPlaceholder('pendingProduct.filter.ProductID')} recordName="pendingProductList" apiName="getPendingProduct" requestType="FetchRecord" />
                    </div>
                    <UploadBulk/>
                    <AddNewProductForm />
                </div>}
            >

                <PendingProductList  scroll={{x : 1400}} recordName="pendingProductList" apiName="getPendingProduct" columns={columns}/>
            </Widget>
            <EditProduct />
            <PharmacyNameModal/>
        </Layout>
    );
};

export default Index;