import React from "react";
import Widget from "components/Widget";
import PharmacyList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SelectFilter from 'components/Filters/SelectFilter'
import SearchBox from 'components/Filters/SearchBox'
import AddNewUserForm from './components/AddNewPharmacy'
import { useDispatch ,useSelector} from 'react-redux'
import EditUser from './components/EditPharmacy'
import { DataRequestAction } from 'redux/actions/CommonHttp';
import { renderStatus, camelCaseToNormal } from 'utils/commonUseFunction'
import dummy from 'assets/images/user-dummy.png'
import moment from 'moment'
import WorkingHourModal from './components/WorkingHourModal'
import { intlPlaceholder } from 'util/intlPlaceholder'
import ResetPasswordModal from './components/ResetPasswordModal'
import DeleteConfirm from 'components/Modal/DeleteConfirm';
const Index = () => {

    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { Working_Hour_Modal } = curdProps


    const showWorkingHourModal = (record) => {
        
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Working_Hour_Modal"
        })
    }
    const columns = [
        {
            title: <IntlMessages id="pharmacy.id" />,
            render: (record) => <span>{record.userId}</span>,
            key: "id",
        },

        {
            title: <IntlMessages id="pharmacy.pharmacyName" />,
            render: (record) => {
                return <div className="gx-d-flex gx-align-items-center">
                    {record.profileImageUrl ? <img alt="" src={record.profileImageUrl} width='30' style={{ borderRadius: '50%' }} height='30' /> :
                        <img alt="" src={dummy} width='30' style={{ borderRadius: '50%' }} height='30' />
                    }
                    <span className="gx-ml-2">{record.pharmacyName && record.pharmacyName}</span> </div>
            },
            key: "doctorName",
        },

        {
            title: <IntlMessages id="pharmacy.ownerName" />,
            render: (record) => <span>{record.fullName}</span>,
            key: "phone"
        },
        {
            title: <IntlMessages id="pharmacy.email" />,
            render: (record) => <span>{record.email}</span>,
            key: "email"
        },
        {
            title: <IntlMessages id="pharmacy.phoneNo" />,
            render: (record) => <span>{record.phoneNumber} </span>,
            key: "location"
        },
        {
            title: <IntlMessages id="pharmacy.address" />,
            render: (record) => <span>{record.address && record.address} </span>,
            key: "location"
        },
        {
            title: <IntlMessages id="pharmacy.workingHours" />,
            // render: (record) => <> <span>{record.workingHour && record.workingHour[0].day} </span>
            //     <span>{record.workingHour && record.workingHour[0].time} </span> </>,
            render: (record) => <span className="gx-text-primary gx-pointer" onClick={() => { showWorkingHourModal(record.workingHour) }}>View</span>,
            key: "location"
        },
        {
            title: <IntlMessages id="pharmacy.socialSecurity" />,
            render: (record) => <span>{camelCaseToNormal(record.socialSecurity)} </span>,
            key: "location"
        },
        {
            title: <IntlMessages id="pharmacy.status" />,
            render: (record) => <span className="gx-text-capitalize">{renderStatus(record.status)} </span>,
            key: "location"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },
    ];
    const changeUserStatus = (userId, status) => {
        dispatch(DataRequestAction('PUT', 'updateUserStatus', 'EditRecord', { _id: userId, status: status }, 'updatingUserStatus', false, 'pharmacyList'));
    }

    const toggleChangePasswordModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Change_PharmacyPassword_Modal"
        })
    }

    const renderEdit = (record) =>{
        if (record.workingHour) {
            record.workingHour && record.workingHour.forEach(item => {
                if (item.to && item.from) {
                    item.to = moment(item.to)
                    item.from = moment(item.from)
                }

            })
        }
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Edit_Pharmacy_Modal"
        })
   
    }
    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">
            
            {(record.status !== "block") && <span className="action-link first gx-link" onClick={() => renderEdit(record)}><IntlMessages id="pharmacy.actions.edit" /></span> }
            {(record.status === "active") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'block')}><IntlMessages id="pharmacy.actions.block" /></span>}
            {(record.status === "block") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'active')}><IntlMessages id="pharmacy.actions.active" /></span>}
            <span style={{ cursor: 'pointer' }} onClick={() => { toggleChangePasswordModal(record) }}><IntlMessages id="user.actions.resetPassword" /></span>
            {(record.status === "deleted") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'active')}><IntlMessages id="pharmacy.actions.active" /></span>}
         {record.status !== "deleted"  && <DeleteConfirm
                type="updateUserStatus"
                recordName={'pharmacyList'}
                method="PUT"
                selectedItem={{...record, status : 'deleted'}}
                apiRequest="EditRecord"
                buttonName="Yes"
                oKText='Yes'
                title={intlPlaceholder('pharmacy.actions.delete_message')} 
                content=""
                Icon={<span style={{ fontSize: '14px' }}><IntlMessages id="pharmacy.actions.delete" /></span>}
            />}
        </div>
    }


    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="pharmacy.pageTitle" />
                    </h4>
                }
                extra={<div className="gx-d-flex gx-mb-0" style={{ flexWrap: 'wrap', gap: '10px' }}>
                    <div style={{ height: '100%' }} >
                        <SelectFilter
                            isShowingAll={true}
                            className="select-filter"
                            apiName="getPharmacy"
                            searchKey="socialSecurity"
                            recordName="pharmacyList"
                            options={[
                                { status: "accepted", label: <IntlMessages id="pharmacy.filter.searchByStatus.accepted" /> },
                                { status: "notAccepted", "label": <IntlMessages id="pharmacy.filter.searchByStatus.notAccepted" /> },
                                // { status: "deleted", "label": <IntlMessages id="pharmacy.filter.searchByStatus.deleted" /> },

                            ]} placeholder={<IntlMessages id="pharmacy.filter.socialSecurity" />} ItemValue="status" label="label" />
                    </div>
                    <div style={{ height: '100%' }} >
                        <SelectFilter
                           
                            className="select-filter"
                            apiName="getPharmacy"
                            searchKey="status"
                            recordName="pharmacyList"
                            options={[
                                { status: "active", label:<IntlMessages id="pharmacy.filter.active" /> },
                                { status: "pending", "label": <IntlMessages id="pharmacy.filter.pending" /> },
                                { status: "block", "label": <IntlMessages id="pharmacy.filter.blocked" /> },
                                { status: "deleted", "label": <IntlMessages id="pharmacy.filter.deleted" /> },
                                { status: "unsubscribed", "label": <IntlMessages id="pharmacy.filter.unsubscribed" /> },
                                // { status: "deleted", "label": <IntlMessages id="pharmacy.filter.searchByStatus.deleted" /> },

                            ]} placeholder={<IntlMessages id="pharmacy.filter.searchByStatus" />} ItemValue="status" label="label" />
                    </div>
                    <div style={{ width: '220px' }}>
                        <SearchBox placeholder={intlPlaceholder('pharmacy.filter.pharmacyName')}
                        recordName="pharmacyList" apiName="getPharmacy" requestType="FetchRecord" />
                    </div>
                    <AddNewUserForm />
                </div>}
            >

                <PharmacyList localeEmptyText={<IntlMessages id="pharmacy.noDataFound" />} scroll={{ x: 1400 }} recordName="pharmacyList" customQuery={{key : 'status', value : 'active'}} apiName="getPharmacy" columns={columns} />
            </Widget>
            <EditUser />
          {Working_Hour_Modal &&  <WorkingHourModal/>}
          <ResetPasswordModal/>
        </Layout>
    );
};

export default Index;