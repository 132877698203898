import React from 'react'
import SimpleModal from "components/Modal/SimpleModal";
import { useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'

const ResetPasswordModal = () => {

    const curdProps = useSelector(state => state.CrudR)
    const { Pharmacy_Name_Modal, initialValues } = curdProps





 
    return (
        <>
           
            <SimpleModal
                visible={Pharmacy_Name_Modal ? Pharmacy_Name_Modal : false}
                modalFor='Pharmacy_Name_Modal'
                width={500}

                title={<IntlMessages id="user.pharmacyNameModal.title" />}
                >
                
                <div style={{padding : 8, borderRadius:'5px', background : '#f5f6ff'}}>
                    {initialValues && initialValues.length > 0 && initialValues.map(item=>{
                        return <p className="gx-mb-1">{item}</p>
                    })}
                </div>
                

                
            </SimpleModal>
        </>
    )
}

export default ResetPasswordModal
