
//this function takes a camel case string and return a space separated sting
//i-e it converts a string  like 'helloThere' or 'HelloThere' to 'Hello There'
import moment from 'moment'
import React from 'react'



export const camelCaseToNormal = (string) => {
    if(string){
        var text = string;
        var result = text.replace(/([A-Z])/g, " $1");
        var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult
    }
   
}
const styles = {
    btn: {

        display: 'inlineBlock',
        padding: '2px 10px',
        borderRadius: '14px',

    },
    yellow: {
        color: '#937935',
        
    },
    green: {
        color: '#51bda7',
        
    },
    red: {
        color: '#EE3F44',
    
    },
    grey: {
        color: '#9CA9B8',
        

    },
    blue: {
        color: '#6cf7de',
        

    },
    theme : {
        color : '#6974e3'
    }

    
}
export const renderStatus = (status,languageId) => {
    
    if(languageId === 'french'){
        if (status === 'newOrder') {
        
            return <span style={{ ...styles.green }}>Nouvelle commande</span>
        }
        if (status === 'approved') {
        
            return <span style={{ ...styles.green }}>Approuvé</span>
        }
        if (status === 'requestedOrder' ) {
            return <span style={{ ...styles.yellow }}>Commande demandée</span>
        }
        if( status === 'quotationSent'){
            return <span style={{ ...styles.yellow }}>Devis envoyé</span>
        }
        if( status === 'riderPicked'){
            return <span style={{ ...styles.theme }}>Cavalier choisi</span>
        }
        if (status === 'completed') {
            return <span style={{ ...styles.blue }}>Complété</span>
        }
        if (status === 'cancelled' || status === 'reject') {
            return <span style={{ ...styles.red }}>Annulé</span>
        }
        if (status === 'unsubscribed') {
            return <span style={{ ...styles.yellow }}>Désabonné</span>
        }
        if (status === 'deleted') {
            return <span style={{ ...styles.red }}>Supprimé</span>
        }
        else {
            return <span >{camelCaseToNormal(status)}</span>
        }
    }
    else{
        if (status === 'newOrder' || status === 'approved' || status === 'active') {
        
            return <span style={{ ...styles.green }}>{camelCaseToNormal(status)}</span>
        }
        if (status === 'requestedOrder') {
            return <span style={{ ...styles.yellow }}>{camelCaseToNormal(status)}</span>
        }
        if( status === 'quotationSent'){
            return <span style={{ ...styles.yellow }}>{camelCaseToNormal(status)}</span>
        }
        if( status === 'riderPicked'){
            return <span style={{ ...styles.theme }}>{camelCaseToNormal(status)}</span>
        }
        if (status === 'completed') {
            return <span style={{ ...styles.blue }}>{camelCaseToNormal(status)}</span>
        }
        if (status === 'cancelled' || status === 'reject' || status === 'block') {
            return <span style={{ ...styles.red }}>{camelCaseToNormal(status)}</span>
        }
        if (status === 'pending' || status === 'unsubscribed') {
            return <span style={{ ...styles.yellow }}>{camelCaseToNormal(status)}</span>
        }
        if (status === 'deleted') {
            return <span style={{ ...styles.red }}>{camelCaseToNormal(status)}</span>
        }
    }
    



}


export const convertStringToHTML = (string) => {
    let parser = new DOMParser();
    let doc = parser.parseFromString(string, 'text/html');
    doc = doc.body.innerHTML
    doc = JSON.stringify(doc)
    doc = doc.replace(/['"]+/g, '')
    return doc
}

export const renderDate = (date, format = "MMMM Do YYYY") => {

    return moment(date).format(format);
}
export const printConsole = (action, data) => {
    return // console.log(`===========${action}=========`, data)
}