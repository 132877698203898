import React from 'react';
import { Form, Input, Button, Spin } from 'antd';
import Widget from '../../Widget'
import { useDispatch, useSelector } from 'react-redux'
import { DataRequestAction } from 'redux/actions/http'
import { setInitUrl } from '../../../redux/actions/Auth'
import { useHistory } from 'react-router-dom'
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages'
const SignIn = (props) => {

    const dispatch = useDispatch();
    const authProps = useSelector(state => state.auth);
    const history = useHistory();
    //When click on the Login button it call the onFinish action
    const { loginFailMessage, loginFail, loginSuccess } = authProps;
    const onFinish = values => {
        //Pass value to action function
        values.email = values.email.toLowerCase()
        dispatch(DataRequestAction('POST', 'login', values, '', 'loginStart', false));

    };




    // const checkPermissionForUser = (userInfo) => {

    //     const Result = [];
    //     const permission = userInfo.permission;
    //     let permissionOnly = _.omitBy(permission, v => (_.isBoolean(v) || _.isFinite(v)) ? false : _.isEmpty(v))
    //     const allKeys = _.keys(permissionOnly);
    //     let notData = ["_id", "createdAt", "roleName", "__v", "roleColor", "userCount", "createdBy", "roleType"];
    //     allKeys.map(Item => {
    //         if (!notData.includes(Item)) {
    //             Result.push(Item);
    //         }
    //         return Item
    //     })

    //     if (Result.includes('dashboard')) {
    //         history.push("/admin/dashboard")
    //     } else {
    //         history.push(`/${Result[0]}`)
    //     }

    // }


    // const checkPermissionForCustomer = (userInfo) => {
    //     const permission = userInfo.permission.permission;
    //     if (permission.includes("dashboard")) {
    //         history.push("/dashboard")
    //     } else {
    //         history.push("/" + permission[0]);
    //     }
    // }


    if (loginSuccess) {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
       
      
        dispatch({
            type: 'update_auth'
        })
        if (userInfo.role === "superAdmin") {
            dispatch(setInitUrl('/dashboard'));
            history.push('/dashboard')
        } else if (userInfo.role === "shopAdmin") {
            dispatch(setInitUrl('/dashboard'));
            history.push('/dashboard')
        } else if (userInfo.role === "procurement") {
            dispatch(setInitUrl('/dashboard'));
            history.push('/dashboard')
        }


    }

    return (
        <Widget styleName="gx-paddingAuth">
            <div className="SignIn gx-px-5 gx-pb-5">
                <div className="welcomeMessage gx-text-center gx-mb-4">
                    <h2 className="gx-font-weight-light gx-mb-0 gx-font-sans-regular gx-blue-font gx-font-35"><IntlMessages id="signIn.welcomeBack" /></h2>
                    <p className="gx-mb-0 gx-gray-font gx-font-sans-regular"><IntlMessages id="signIn.loginToYourAccount" /></p>
                    {loginFail && <span style={{
                        color: 'red'
                    }}>{loginFailMessage}</span>}
                </div>

                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        className="gx-p-0 gx-m-0 gx-mb-3 lower-case-email"

                        rules={[{ required: true, message:   <IntlMessages id="signIn.emailMessage" /> }]}
                    >
                        <Input placeholder="Email" prefix={<MailOutlined style={{
                            marginRight: '5px',
                            color: '#00C980'
                        }} />} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        className="gx-p-0 gx-m-0 gx-mb-2"
                        rules={[{ required: true, message: <IntlMessages id="signIn.passwordMessage" /> }]}
                    >
                        <Input
                            type="password"
                            placeholder="Password"
                            prefix={<LockOutlined style={{
                                marginRight: '5px',
                                color: '#00C980'
                            }} />}
                        />
                    </Form.Item>
                   


                    <Form.Item className="gx-p-0 gx-m-0 gx-mt-4">
                        {!authProps.loginStart && <Button type="primary" htmlType="submit" className="login-form-button gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center gx-font-sans-regular" style={{ lineHeight: '0px' }}>
                        <IntlMessages id="signIn.loginBtn" />
                        </Button>}
                        {authProps.loginStart && <Spin><Button type="primary" htmlType="button" className="login-form-button gx-mb-0 gx-d-flex gx-w-100 gx-flex-row gx-justify-content-center gx-font-sans-regular" style={{ lineHeight: '0px' }}>
                        <IntlMessages id="signIn.loginBtn" />
                        </Button></Spin>}

                    </Form.Item>
                </Form>

            </div>

        </Widget>
    );
};

export default SignIn;