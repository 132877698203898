import React from 'react'
import SimpleModal from "components/Modal/SimpleModal";
import {  useSelector } from "react-redux";
import { renderDate} from 'utils/commonUseFunction'
const ResetPasswordModal = () => {



    const curdProps = useSelector(state => state.CrudR)
    const { Working_Hour_Modal, initialValues } = curdProps


 

    const renderWorkingHours = () =>{
        return initialValues && initialValues.map(item=>{
            if(item.time !== 'custom'){
                return <div>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>Day</span>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>{item.day}</span>
                <span style={{width : '20%', textTransform : 'capitalize'}}>{item.time}</span>
            </div>
            }
            else{
                return <div>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>Day</span>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>{item.day}</span>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>{item.time}</span>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>{renderDate(item.to,'hh:mm A')}</span>
                <span style={{width : '20%', display : 'inline-block', textTransform : 'capitalize'}}>{renderDate(item.from,'hh:mm A')}</span>
            </div>
            }
                
        })
    }

    return (
        <>
            
            <SimpleModal
                width={500}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">{'Working Hours'} </span>}
                modalFor="Working_Hour_Modal"
                visible={Working_Hour_Modal ? Working_Hour_Modal : false}>

                {renderWorkingHours()}

                
            </SimpleModal>
        </>
    )
}

export default ResetPasswordModal
