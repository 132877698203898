import React, { useEffect } from "react";
import {  DataGetAction } from "redux/actions/CommonHttp";

import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { Form,  Row, Col, Button, Select, Upload,Spin,message as AntMessage } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { FormDataRequestAction } from "../../../../../redux/actions/http";
import { useHistory } from "react-router-dom";

const { Option } = Select
const UploadBulk = () => {
    const curdProps = useSelector((state) => state.CrudR);
    const { Bulk_Upload_Modal, allPharmacies, allCategories ,Loader,multipleAdd_success,message} = curdProps;
    const dispatch = useDispatch();
    const history = useHistory()

    if(multipleAdd_success){
        history.push('/product/approvedProduct')
        AntMessage.success(message)
        dispatch({
            type : 'ResetAll_State'
        })
    }

    const normFile = (e) => {
        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };

    const toggleBulkModal = () => {
        dispatch({
            ModalName: "Bulk_Upload_Modal",
            type: "Show_Modal",
            payload: null,
        });
    };

    const hideBulkModal = () => {
        dispatch({
            ModalName: "Bulk_Upload_Modal",
            type: "Hide_Modal",
            payload: null,
        });
    };

    const fetchList = () => {
        dispatch(
            DataGetAction(
                "getPharmacyDropdown",
                "FetchRecord",
                { query: "all" },
                "",
                "",
                "allPharmacies"
            )
        );
        dispatch(DataGetAction('getCategory', 'FetchRecord', { query: 'all' }, '', '', 'allCategories'))
    };

    useEffect(fetchList, [Bulk_Upload_Modal]);

    const bulkUpload = (values) => {

   
        dispatch(
            FormDataRequestAction(
                "bulkImportProduct",
                "AddMultipleRecord",
                values,
                "StartSpinner",
                "Bulk_Upload_Modal",
                "pendingProductList",
                
            )
        );
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };

    return (
        <div>
            <Button
                type="primary"
                className="pt-0"
                style={{ lineHeight: "0px" }}
                onClick={() => toggleBulkModal()}
            >
               <IntlMessages id="product.addBulkProductModal.bulkUpload" />
            </Button>

            <SimpleModal
                width={500}
                title={
                    <span style={{ color: "#041649" }} className="gx-font-sans-bold">
                        {<IntlMessages id="product.addBulkProductModal.uploadBulkProducts" />}{" "}
                    </span>
                }
                modalFor="Bulk_Upload_Modal"
                visible={Bulk_Upload_Modal ? Bulk_Upload_Modal : false}
            >
               <div className="gx-d-flex gx-justify-content-between gx-mb-4"> 
                   <span style={{marginRight : '25px'}}>
                      
                       <IntlMessages id="product.addBulkProductModal.uploadScv" />
                       </span>
                  <a 
                    href="https://storage.gra.cloud.ovh.net/v1/AUTH_4a7215cdd9274c998b018eebb3b6a063/delimed_public/file-1638257492015.csv" 
                    target="_blank"
                    rel="noopener noreferrer"
                    > 
                        <span className="gx-text-primary">
                                <IntlMessages id="product.addBulkProductModal.sample" /></span></a>
               </div>
                <Form layout="vertical" name="editProfile" onFinish={bulkUpload}>
                    <Row className="gx-d-flex gx-flex-row gx-align-items-center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-pl-0">
                            <Form.Item
                                name="pharmacyId"
                                label={<IntlMessages id="product.addProductModal.pharmacy" />}
                                rules={[
                                    {
                                        required: true,
                                        message: (
                                            <IntlMessages id="product.addProductModal.pharmacy" />
                                        ),
                                    },
                                ]}
                            >
                                <Select mode="multiple">
                                    {allPharmacies &&
                                        allPharmacies.map((item) => {
                                            return (
                                                <Option key={item._id} value={item._id}>
                                                    {item.pharmacyName}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="categoryId"
                                label={<IntlMessages id="product.addProductModal.category" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="product.addProductModal.category" />
                                    },
                                ]}
                            >
                                <Select  >
                                    {allCategories && allCategories.map(item => {
                                        return <Option key={item.id} value={item._id}>{item.name}</Option>
                                    })}


                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="file"
                                label={<IntlMessages id="product.addBulkProductModal.csvFile" />}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                            >
                                <Upload  name="logo" customRequest={dummyRequest}  listType="picture">
                                    <Button className="gx-pt-0 gx-w-100" block icon={<UploadOutlined />}><IntlMessages id="product.addBulkProductModal.clickToUpload" /></Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                    </Row>

                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
                            <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">

                                <Form.Item className="gx-mb-0">
                                    <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => hideBulkModal()}>
                                        <IntlMessages id="settings.editModal.cancel" />
                                    </Button>
                                </Form.Item>
                                <Form.Item className="gx-mb-0">
                                    {Loader && <Spin>   <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="button" disbaled>
                                        <IntlMessages id="settings.editModal.save" />
                                    </Button></Spin>}

                                   {!Loader && <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="submit">
                                        <IntlMessages id="settings.editModal.save" />
                                    </Button>}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </div>
    );
};

export default UploadBulk;
