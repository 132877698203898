import React from 'react'
import Widget from 'components/Widget'
import IntlMessages from 'util/IntlMessages'
import RecentPharmacy from "components/Table/List";
import { useHistory } from 'react-router-dom'
import { renderDate } from 'utils/commonUseFunction'
const RecentPharmacies = () => {

    const history = useHistory();



    const columns = [
        {
            title: <IntlMessages id="pharmacy.id" />,
            render: (record) => <span>{record.userId}</span>,
            key: "id",
        },
        
        {
            title: <IntlMessages id="pharmacy.pharmacyName" />,
            render: (record) =>  <span className="gx-ml-2">{record.pharmacyName && record.pharmacyName }</span> ,
            key: "doctorName",
        },

        {
            title: <IntlMessages id="pharmacy.ownerName" />,
            render: (record) => <span>{record.fullName}</span>,
            key: "phone"
        },
        {
            title: <IntlMessages id="pharmacy.email" />,
            render: (record) => <span>{record.email}</span>,
            key: "email"
        },
        {
            title: <IntlMessages id="pharmacy.dateAdded" />,
            render: (record) => <span>{renderDate(record.createdAt)}</span>,
            key: "renderDate"
        },
    ];
    return (
        <div>
            
            <Widget  extra={<span className="gx-link" onClick={()=>{history.push('/pharmacy')}}>View All</span>} title={<h6 className="gx-text-grey gx-font-weight-bold"><IntlMessages id="dashboard.recentlyAddedPharmacies" /></h6>}>
            <RecentPharmacy pagination={{
                    total: 5,
                    pageSize: 5,
                    hideOnSinglePage: true
                }} styleName="borderLessTable" scroll={{x : '700px'}}   recordName="recentPharmacies" bordered={false}  apiName="getRecentPharmacy" columns={columns} />
            {/* <Table className="borderLessTable" dataSource={data} columns={columns}  bordered={false} pagination={{
                    total: 5,
                    pageSize: 5,
                    hideOnSinglePage: true
                }} /> */}
            </Widget>
            
        </div>
    )
}

export default RecentPharmacies
