import React from 'react'
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'
import { Form, Input, Row, Col, Button, Spin } from 'antd';
import { DataRequestAction } from "redux/actions/CommonHttp";
const ResetPasswordModal = () => {

    const curdProps = useSelector(state => state.CrudR)
    const { Change_PharmacyPassword_Modal, initialValues,Loader } = curdProps



    const dispatch = useDispatch();
    const [form]= Form.useForm()

    const changePassword = values => {
        values.userId = initialValues._id
        dispatch(DataRequestAction('PUT', 'changeUserPassword', 'EditRecord', values, 'StartSpinner', 'Change_PharmacyPassword_Modal', 'pharmacyList'))
    };
    const toggleChangePasswordModal = () => {
        dispatch({
            type: 'Hide_Modal',
            payload: null,
            ModalName: "Change_PharmacyPassword_Modal"
        })
    }
    // if (success) {
    //     message.success(textMessage)
    //     dispatch({
    //         type: 'reset_profilesettings'
    //     })
    // }
    // if (error) {
    //     message.error(textMessage)
    //     dispatch({
    //         type: 'reset_profilesettings'
    //     })
    // }
    if(!Change_PharmacyPassword_Modal){
        // console.log('==========success==========')
         form.resetFields()
     }
 
    return (
        <>
           
            <SimpleModal
                visible={Change_PharmacyPassword_Modal ? Change_PharmacyPassword_Modal : false}
                modalFor='Change_PharmacyPassword_Modal'
                width={500}

                title={<IntlMessages id="user.passwordModal.title" />}>

                <Form
                    layout="vertical"
                    name="resetPassword"
                    onFinish={changePassword}
                    form={form}
                >
                    
                    <Row className="gx-d-flex gx-flex-row">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
                            <Form.Item
                                name="password"
                                label={<IntlMessages id="user.passwordModal.newPassword" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="user.passwordModal.newPassword_message" />,
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">
                            <Form.Item
                                name="confirmPassword"
                                label={<IntlMessages id="user.passwordModal.confirmPassword" />}
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="user.passwordModal.confirmPassword_message" />,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(<IntlMessages id="user.passwordModal.passwordNotMatchMessage" />);
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
                            <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">
                                <Form.Item className="gx-mb-0">
                                    <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => toggleChangePasswordModal()}>
                                        <IntlMessages id="user.passwordModal.cancel" />
                                    </Button>
                                </Form.Item>
                                <Form.Item className="gx-mb-0">
                                    {Loader && <Spin><Button type="primary" className="gx-pt-0 gx-mb-0" disbaled htmlType="button">
                                        <IntlMessages id="user.passwordModal.save" />
                                    </Button></Spin>}
                                    {!Loader && <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="submit">
                                        <IntlMessages id="user.passwordModal.save" />
                                    </Button>}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </>
    )
}

export default ResetPasswordModal
