import React, { useState, useEffect } from 'react'
//import { DataRequestAction } from "redux/actions/CommonHttp";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'
import { Form, Row, Col, Button, Table } from 'antd';
import Widget from "components/Widget"


const PrescriptionModal = () => {

    let currency = process.env.REACT_APP_CURRENCY

    const dispatch = useDispatch();
    const [form] = Form.useForm()


    const [totalPrice, setTotalPrice] = useState(0)

    const curdProps = useSelector(state => state.CrudR)
    const { Add_Prescription_Modal, initialValues, success } = curdProps

    if (success) {
        form.resetFields()
        setTotalPrice(0)
        dispatch({
            type: 'ResetAll_State'
        })
    }

    const setInitialStates = () => {

        if (initialValues) {
            let total = 0
            initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.forEach(item => {
                total = total + (parseFloat(item.productId.price) * parseFloat(item.qty))
            })
            setTotalPrice(total)
        }

    }

    useEffect(setInitialStates, [initialValues])



    const { fullName, email, phoneNumber, address } = initialValues || {}


    const openNewOrderModal = () => {
        dispatch({
            type: 'Hide_Modal',
            payload: null,
            ModalName: "Add_Prescription_Modal"
        })
    }




    const RenderCol = ({ title, desc }) => {
        return <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-pl-0 gx-mb-2">
            <div className="gx-d-flex gx-flex-column">
                <label htmlFor="" className="gx-mb-2">{title}</label>
                <p style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', minHeight: '40px' }}>{desc}</p>
            </div>
        </Col>


    }

    // const renderNonMedicalProducts = () => {
    //     return initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.map(item => {
    //         return <div className="gx-d-flex gx-justify-content-between">
    //             <span className="gx-flex-1 ">{item.productId.productName}</span>
    //             <span className="gx-flex-1 gx-text-center">{item.qty}</span>
    //             <span className="gx-flex-1 gx-text-center">{currency}{item.productId.price}</span>
    //         </div>
    //     })
    // }

    const calculateTotalPrice = (qty, price) => {
        let quantity = parseFloat(qty)
        let priceItem = parseFloat(price)

        let total = quantity * priceItem

        if (Number.isInteger(parseFloat(total))) {
            return total
        }
        else {
            total = parseFloat(total).toFixed(2)
            return total
        }
    }
    const columns = [
        {
            title: <IntlMessages id="Order.addNewOrderModal.productsName" />,
            render: (record) => <span>{record.productId.productName}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="Order.addNewOrderModal.qty" />,
            render: (record) => <span>{record.qty}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="Order.addNewOrderModal.price" />,
            render: (record) => <span>{currency}{record.productId.price}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="Order.addNewOrderModal.totalPrice" />,
            render: (record) => <span>{currency}{calculateTotalPrice(record.qty, record.productId.price)}</span>,
            key: "No.",
        },
    ]
    const calculateNonMedicinePrice = () => {
        let total = 0
        initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.forEach(item => {
            total = total + (parseFloat(item.productId.price) * parseFloat(item.qty))
        })
        return total
    }



    // const submitForm = () => {

    //     dispatch(DataRequestAction('PUT', 'createNonMedicalOrder', 'DeleteRecord', initialValues, 'StartSpinner', 'Add_Prescription_Modal', 'newOrderList'))
    // };
    // const cancelOrder = () => {

    //     dispatch(DataRequestAction('PUT', 'cancelOrder', 'DeleteRecord', initialValues, 'StartSpinner', 'Add_Prescription_Modal', 'newOrderList'))
    // };
    const getTotalPrice =() =>{
        if(totalPrice && initialValues && initialValues.deliveryCost){
            let total = parseFloat(totalPrice) + parseFloat(initialValues.deliveryCost)
            total = total.toFixed(2)
            return total
        }
       
    }
    return (
        <div>


            <SimpleModal
                destroyOnClose={true}
                form={form}
                width={700}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">
                    {<IntlMessages id="Order.addNewOrderModal.title" />} 
                    <span className="gx-ml-2">({(initialValues && initialValues.orderFor) ? initialValues.orderFor : 'Self'})</span>
                    </span>}
                modalFor="Add_Prescription_Modal"
                visible={Add_Prescription_Modal ? Add_Prescription_Modal : false}>

                <span></span>



                <Form
                    layout="vertical"
                    name="editProfile"
                    form={form}



                >
                    <Row className="gx-d-flex gx-flex-row ">




                        <Widget title={<IntlMessages id="Order.addNewOrderModal.clientInfo" />}>
                            <Row className="gx-d-flex gx-flex-row ">
                                <RenderCol title={<IntlMessages id="Order.addNewOrderModal.name" />} desc={fullName} />
                                <RenderCol title={<IntlMessages id="Order.addNewOrderModal.email" />} desc={email} />
                                <RenderCol title={<IntlMessages id="Order.addNewOrderModal.phone" />} desc={phoneNumber} />
                                <RenderCol title={<IntlMessages id="Order.addNewOrderModal.address" />} desc={address} />
                                {/* <RenderCol title={<IntlMessages id="Order.addNewOrderModal.vitaleCard" />} desc={vitaleCardNumber} />
                                    <RenderCol title={<IntlMessages id="Order.addNewOrderModal.insuranceCard" />} desc={insuranceCardNumber} /> */}
                            </Row>

                        </Widget>



                        <Widget title={<IntlMessages id="Order.addNewOrderModal.products" />} styleName="full-width">
                            <Row className="gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-start">
                                <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-pl-0">
                                    {/* <div className="gx-d-flex gx-justify-content-between">
                                            <span className="gx-font-weight-bold gx-text-black gx-flex-1 "><IntlMessages id="Order.addNewOrderModal.productsName" /></span>
                                            <span className="gx-font-weight-bold gx-text-black gx-flex-1"><IntlMessages id="Order.addNewOrderModal.qty" /></span>
                                            <span className="gx-font-weight-bold gx-text-black gx-flex-1"><IntlMessages id="Order.addNewOrderModal.price" /></span>
                                        </div>
                                        {renderNonMedicalProducts()} */}
                                    <Table size="small" pagination={{
                                        total: 5,
                                        pageSize: 5,
                                        hideOnSinglePage: true
                                    }} bordered={false} columns={columns} dataSource={initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct} />
                                </Col>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '6px' }}>
                                        <h6><IntlMessages id="Order.addNewOrderModal.grandTotal" /></h6>

                                        <div className="gx-d-flex gx-justify-content-between">
                                            <span><IntlMessages id="Order.addNewOrderModal.products" /></span>
                                            <span>{currency}{calculateNonMedicinePrice()}</span>
                                        </div>
                                        <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="Order.addNewOrderModal.deliveryCost" /></span>
                                                <span>{currency}{initialValues && initialValues.deliveryCost && parseFloat(initialValues.deliveryCost).toFixed(2)}</span>
                                            </div>
                                        <div className="gx-d-flex gx-justify-content-between">
                                            <span><IntlMessages id="Order.addNewOrderModal.total" /></span>
                                            <span>{currency}{getTotalPrice() } </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Widget>



                    </Row>






                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                            <div className={`gx-d-flex ${!false ? 'gx-justify-content-end' : 'gx-justify-content-between'} gx-align-items-center`}>

                                <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end gx-mt-2" style={{ gap: '10px' }}>
                                    <Form.Item className="gx-mb-0 gx-mr-3">
                                        <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => openNewOrderModal()}>
                                            <IntlMessages id="Order.addNewOrderModal.cancel" />
                                        </Button>
                                    </Form.Item>

                                    {/* <Form.Item className="gx-mb-0 gx-mr-3">
                                        <Button type="primary" className="gx-pt-0 gx-mb-0" onClick={() => { cancelOrder() }}>
                                            <IntlMessages id="Order.addNewOrderModal.reject" />
                                        </Button>
                                      

                                    </Form.Item>
                                    <Form.Item className="gx-mb-0">
                                        <Button type="primary" className="gx-pt-0 gx-mb-0" onClick={() => { submitForm() }}>
                                            <IntlMessages id="Order.addNewOrderModal.process" />
                                        </Button>
                                      

                                    </Form.Item> */}
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </div>
    )
}

export default PrescriptionModal
