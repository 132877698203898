import React, { useState, useEffect } from 'react'
import { DataRequestAction } from "redux/actions/CommonHttp";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'
import { Form, Row, Col, Button, Carousel, Input, InputNumber, Spin, Image, Table } from 'antd';
import Widget from "components/Widget"
import CardVitaleModal from './CardVitaleModal'
import CardInsuranceModal from './CardInsuranceModal'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
const EditProfile = () => {


    const [openDetail, setOpenDetail] = useState(true)

    const [form] = Form.useForm()

    let currency = process.env.REACT_APP_CURRENCY

    const curdProps = useSelector(state => state.CrudR)
    const { Add_NewOrder_Modal, initialValues } = curdProps

    const pageInfoProps = useSelector(state => state.pageInfo)
    const {  arrayTempLinkSpinner, prescriptionDocuments } = pageInfoProps


    const setInitialStates = () => {

        if (initialValues) {
            let total = 0
            initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.forEach(item => {
                total = total + (parseFloat(item.productId.price) * parseFloat(item.qty))
            })

        }
        if (initialValues && initialValues.prescriptionUrl && initialValues.prescriptionUrl.length) {

            dispatch(DataRequestAction('POST', 'getArrayTempLink', 'getArrayTempLink', initialValues.prescriptionUrl, 'gettingArrayTempLink', '', ''))
        }
        form.resetFields()

        setOpenDetail(true)
        form.setFieldsValue(initialValues)
    }

    useEffect(setInitialStates, [initialValues])



    const { fullName, email, phoneNumber, address, totalPrice } = initialValues || {}
    const dispatch = useDispatch();

    const openNewOrderModal = () => {
        dispatch({
            type: 'Hide_Modal',
            payload: null,
            ModalName: "Add_NewOrder_Modal"
        })
    }

    const calculateTotalPrice = (qty, price) => {
        let quantity = parseFloat(qty)
        let priceItem = parseFloat(price)

        let total = quantity * priceItem

        if (Number.isInteger(parseFloat(total))) {
            return total
        }
        else {
            total = parseFloat(total).toFixed(2)
            return total
        }
    }
    const columns = [
        {
            title: <IntlMessages id="Order.addNewOrderModal.productsName" />,
            render: (record) => <span>{record.productId.productName}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="Order.addNewOrderModal.qty" />,
            render: (record) => <span>{record.qty}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="Order.addNewOrderModal.price" />,
            render: (record) => <span>{currency}{record.productId.price}</span>,
            key: "No.",
        },
        {
            title: <IntlMessages id="Order.addNewOrderModal.totalPrice" />,
            render: (record) => <span>{currency}{calculateTotalPrice(record.qty, record.productId.price)}</span>,
            key: "No.",
        },
    ]

    const RenderCol = ({ title, desc }) => {
        return <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-pl-0 gx-mb-2">
            <div className="gx-d-flex gx-flex-column">
                <label htmlFor="" className="gx-mb-2">{title}</label>
                <p style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>{desc}</p>
            </div>
        </Col>


    }



    const calculateNonMedicinePrice = () => {
        let total = 0
        initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct.forEach(item => {
            total = total + (parseFloat(item.productId.price) * parseFloat(item.qty))
        })
        console.log('============ total value========', total)
        return total
    }


    const SampleNextArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{
                    ...style,
                }}
                onClick={onClick}
            >
                <RightOutlined />
            </div>
        )
    }
    const SamplePrevArrow = props => {
        const { className, style, onClick } = props
        return (
            <div
                className={className}
                style={{
                    ...style,



                }}
                onClick={onClick}
            >
                <LeftOutlined />
            </div>
        )
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }

    const calculateMedicinePrice = () => {
        let totalMedicine = totalPrice - calculateNonMedicinePrice()
        console.log('================totalMedicine======', totalMedicine)

        if (Number.isInteger(parseFloat(totalMedicine))) {
            return totalMedicine
        }
        else {
            totalMedicine = parseFloat(totalMedicine).toFixed(2)
            return totalMedicine
        }
    }

    const getTotalPrice =() =>{
        if(totalPrice && initialValues && initialValues.deliveryCost){
            let total = parseFloat(totalPrice) + parseFloat(initialValues.deliveryCost)
            total = total.toFixed(2)

            return total
        }
       
    }
    return (
        <div>


            <SimpleModal
                width={1200}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">
                    {<IntlMessages id="Order.addNewOrderModal.title" />} 
                    <span className="gx-ml-2">({(initialValues && initialValues.orderFor) ? initialValues.orderFor : 'Self'})</span>
                    </span>}
                modalFor="Add_NewOrder_Modal"
                visible={Add_NewOrder_Modal ? Add_NewOrder_Modal : false}>

                <span></span>



                <Form
                    layout="vertical"
                    name="editProfile"
                    form={form}
                    initialValues={initialValues && initialValues}

                >
                    <Row className="gx-d-flex gx-flex-row ">
                        <Col xl={10} lg={10} md={10} sm={24} xs={24} className="gx-pl-0">
                            {arrayTempLinkSpinner ? <Spin size="large" /> : <Carousel  draggable={true} {...settings} arrows={true}>

                                {prescriptionDocuments && prescriptionDocuments.length && prescriptionDocuments.map(item => {
                                    console.log('===========item========', item)
                                    if (item && item.type === 'pdf') {
                                        // return <div className="pdf-container" >
                                        //     <Document
                                        //             file={item.file}
                                        //             onLoadSuccess={onDocumentLoadSuccess}
                                        //             renderMode="svg"
                                        //             options={{
                                        //                 cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                                        //                 cMapPacked: true,
                                        //             }}
                                        //         >
                                        //             <Page pageNumber={pageNumber} />
                                        //         </Document>

                                        //         <div className="gx-d-flex gx-flex-row gx-align-items-center gx-justify-content-between gx-p-2">
                                        //             <div className="gx-d-flex gx-flex-row">
                                        //                 {pageNumber < numPages && <button className="gx-mr-3" onClick={() => setPageNumber(pageNumber + 1)}>
                                        //                     Next page
                                        //                 </button>}
                                        //                 {pageNumber > 1 && <button onClick={() => setPageNumber(pageNumber - 1)}>
                                        //                     Previous page
                                        //                 </button>}
                                        //             </div>
                                        //             <p>Page {pageNumber} of {numPages}</p>
                                        //             <p></p>
                                        //         </div>

                                        // </div>

                                        return <iframe src={item.file} title="prescription" frameborder="0" height="100%" width="100%">
                                        </iframe>
                                        // return <MyPdfViewer filepath={item.file} />
                                        // return   <iframe src={`https://docs.google.com/viewerng/viewer?url=${item.file}&embedded=true" frameborder="0" height="100%" width="100%`} />
                                        // return <PDFViewer
                                        //     document={{
                                        //         url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
                                        //     }}
                                        // />

                                    }
                                    else {
                                        return <Image
                                            width="100%"
                                            src={item.file}
                                        />
                                    }


                                })}

                            </Carousel>}

                        </Col>
                        {openDetail && <Col xl={14} lg={14} md={14} sm={24} xs={24} className="gx-pr-0">

                            <h5 className="gx-mb-2"><IntlMessages id="Order.addNewOrderModal.prescription" /></h5>
                            <Widget title={<IntlMessages id="Order.addNewOrderModal.clientInfo" />}>
                                <Row className="gx-d-flex gx-flex-row ">
                                    <RenderCol title={<IntlMessages id="Order.addNewOrderModal.name" />} desc={fullName} />
                                    <RenderCol title={<IntlMessages id="Order.addNewOrderModal.email" />} desc={email} />
                                    <RenderCol title={<IntlMessages id="Order.addNewOrderModal.phone" />} desc={phoneNumber} />
                                    <RenderCol title={<IntlMessages id="Order.addNewOrderModal.address" />} desc={address} />
                                    {/* <RenderCol title={<IntlMessages id="Order.addNewOrderModal.vitaleCard" />} desc={vitaleCardNumber} />
                                    <RenderCol title={<IntlMessages id="Order.addNewOrderModal.insuranceCard" />} desc={insuranceCardNumber} /> */}
                                </Row>

                            </Widget>


                            <Widget title={<IntlMessages id="Order.addNewOrderModal.medicine" />}>
                                <Row className="gx-d-flex gx-flex-row gx-justify-content-around">
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Form.Item
                                            name="totalNumberOfProducts"
                                            label={<IntlMessages id="Order.addNewOrderModal.noOfProducts" />}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="Order.addNewOrderModal.noOfProducts_message" />
                                                },
                                            ]}
                                        >
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <Form.Item
                                            name="chargeToCustomer"
                                            label={<IntlMessages id="Order.addNewOrderModal.chargeToCustomer" />}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: <IntlMessages id="Order.addNewOrderModal_message" />
                                                },
                                            ]}
                                        >
                                            <InputNumber disabled style={{ width: '100%' }} formatter={value => `${currency} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </Widget>

                            <Widget title={<IntlMessages id="Order.addNewOrderModal.nonMedicalProducts" />}>
                                <Row className="gx-d-flex gx-flex-row gx-justify-content-between gx-align-items-start">
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="gx-pl-0">

                                        <Table size="small" pagination={{
                                            total: 5,
                                            pageSize: 5,
                                            hideOnSinglePage: true
                                        }} bordered={false} columns={columns} dataSource={initialValues && initialValues.nonMedicalProduct && initialValues.nonMedicalProduct.length && initialValues.nonMedicalProduct} />
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>



                                        <div style={{ border: '1px solid #ddd', borderRadius: '5px', padding: '6px' }}>
                                            <h6><IntlMessages id="Order.addNewOrderModal.grandTotal" /></h6>
                                            <div className="gx-d-flex gx-flex-row gx-justify-content-between">
                                                <span><IntlMessages id="Order.addNewOrderModal.medicine" /></span>
                                                {totalPrice ? <span>{currency}{calculateMedicinePrice()}</span> :
                                                    <span>{currency}{0}</span>
                                                }
                                            </div>
                                            <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="Order.addNewOrderModal.nonMedicine" /></span>
                                                <span>{currency}{calculateNonMedicinePrice()}</span>
                                            </div>
                                            <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="Order.addNewOrderModal.deliveryCost" /></span>
                                                <span>{currency}{initialValues && initialValues.deliveryCost && parseFloat(initialValues.deliveryCost).toFixed(2)}</span>
                                            </div>
                                            <div className="gx-d-flex gx-justify-content-between">
                                                <span><IntlMessages id="Order.addNewOrderModal.total" /></span>
                                                {totalPrice ? <span>{currency}{ getTotalPrice()  } </span> : <span>{currency}{calculateNonMedicinePrice()}</span>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Widget>

                        </Col>}
                    </Row>






                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} >
                            <div className={`gx-d-flex ${!openDetail ? 'gx-justify-content-end' : 'gx-justify-content-between'} gx-align-items-center`}>

                                {openDetail && <div className="gx-d-flex" style={{ gap: '10px' }}>

                                    <CardVitaleModal vitaleCardNumber={initialValues && initialValues.vitalCard} />
                                    <CardInsuranceModal insuranceCardNumber={initialValues && initialValues.insuranceCard} />

                                </div>}
                                <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end " style={{ gap: '10px' }}>
                                    <Form.Item className="gx-mb-0 gx-mr-3">
                                        <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() => openNewOrderModal()}>
                                            <IntlMessages id="Order.addNewOrderModal.cancel" />
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </div>
    )
}

export default EditProfile
