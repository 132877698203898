import React from "react";
import Widget from "components/Widget";
import UserList from "components/Table/List";
import IntlMessages from 'util/IntlMessages'
import TableAction from 'components/Table/Actions'
import Layout from 'components/LayoutForPortal'
import SearchBox from 'components/Filters/SearchBox'
import DateFilter from 'components/Filters/DateFilter'
import { useDispatch } from 'react-redux'
import { DataRequestAction } from 'redux/actions/CommonHttp';
import { renderStatus, renderDate, camelCaseToNormal } from 'utils/commonUseFunction'
import ResetPasswordModal from './components/ResetPasswordModal'

import { intlPlaceholder } from 'util/intlPlaceholder'
import VitalCardModal from './components/VitalCardModal'
import InsuranceCardModal from './components/InsuranceCardModal'
const Index = () => {

    const dispatch = useDispatch()


    const showCard = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Vital_Card_Modal"
        })
    }
    const showInsuranceCard = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Insurance_Card_Modal"
        })
    }


    const columns = [
        {
            title: <IntlMessages id="user.id" />,
            render: (record) => <span>{record.userId}</span>,
            key: "id",
        },
        {
            title: <IntlMessages id="user.name" />,
            render: (record) => <span>{record.fullName ? record.fullName : record.firstName + " " + record.lastName}</span>,
            key: "fullName"
        },
        {
            title: <IntlMessages id="user.email" />,
            render: (record) => <span>{record.email}</span>,
            key: "email"
        },
        {
            title: <IntlMessages id="user.phoneNo" />,
            render: (record) => <span>{record.phoneNumber}</span>,
            key: "phone"
        },

        {
            title: <IntlMessages id="user.address" />,
            render: (record) => <span>{record.address} </span>,
            key: "address",
            width: 250

        },

        {
            title: <IntlMessages id="user.registrationDate" />,
            render: (record) => <span>{renderDate(record.createdAt)} </span>,
            key: "regdData"
        },

        {
            title: <IntlMessages id="user.platform" />,
            render: (record) => <span>{camelCaseToNormal(record.plateForm)} </span>,
            key: "platform"
        },
        {
            title: <IntlMessages id="user.vitalCard" />,
            render: (record) => <span className="gx-text-primary gx-pointer" onClick={() => { showCard(record.vitalCard) }}>View</span>,
            key: "vitalCard"
        },
        {
            title: <IntlMessages id="user.insuranceCard" />,
            render: (record) => <span className="gx-text-primary gx-pointer" onClick={() => { showInsuranceCard(record.insuranceCard) }}>View</span>,
            key: "insuranceCard"
        },
        // {
        //     title: <IntlMessages id="user.insuranceExpiryDate" />,
        //     render: (record) => <span>{renderDate(record.insuranceExpireDate)} </span>,
        //     key: "insuranceExpiryDate"
        // },
        {
            title: <IntlMessages id="user.status" />,
            render: (record) => <span className="gx-text-capitalize">{renderStatus(record.status)} </span>,
            key: "status"
        },
        {
            title: '',
            render: (record) => <TableAction> {renderActions(record)}</TableAction>,
            key: "action"
        },
    ];


    const changeUserStatus = (userId, status) => {
        dispatch(DataRequestAction('PUT', 'updateUserStatus', 'EditRecord', { userId: userId, status: status }, 'updatingUserStatus', false, "usersList"));
    }

    const changePasswordModal = (record) => {
        dispatch({
            type: 'Show_Modal',
            payload: record,
            ModalName: "Change_Password_Modal"
        })
    }

    const renderActions = (record) => {
        return <div className="gx-d-flex gx-flex-column">

            {(record.status === "active") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'block')}><IntlMessages id="user.actions.block" /></span>}
            {(record.status === "block") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'active')}><IntlMessages id="user.actions.unBlock" /></span>}
           {(record.status === 'active' || record.status === 'block')  &&  <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'unsubscribed')}><IntlMessages id="user.actions.unsubscribe" /></span>}
           {(record.status === 'unsubscribed' )  &&  <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'active')}><IntlMessages id="user.actions.restore" /></span>}
            <span style={{ cursor: 'pointer' }} onClick={() => { changePasswordModal(record) }}><IntlMessages id="user.actions.resetPassword" /></span>
        </div>
    }




    return (
        <Layout>
            <Widget
                styleName="mainTable"
                title={
                    <h4
                        className="gx-mb-0 gx-font-gilory-bold"
                        style={{ color: "#14244E" }}>
                        <IntlMessages id="user.pageTitle" />
                    </h4>
                }
                extra={<div className="gx-d-flex gx-mb-0 filters-container" style={{ flexWrap: 'wrap', gap: '10px' }}>
                    <div style={{ width: '240px', marginRight: '12px' }}>
                        <DateFilter placeholder={[intlPlaceholder('user.filter.startDate'), intlPlaceholder('user.filter.endDate')]} apiName="getUsers" requestType="FetchRecord" recordName="usersList" />
                    </div>
                    <div style={{ width: '220px' }}>
                        <SearchBox placeholder={intlPlaceholder('user.filter.nameEmailPlatform')} recordName="usersList" apiName="getUsers" requestType="FetchRecord" />
                    </div>
                </div>}
            >

                <UserList
                    scroll={{ x: 1400 }}
                    recordName="usersList"
                    apiName="getUsers"
                    columns={columns} />
                {/* <Table

                    columns={columns1}
                    dataSource={tempRecord}
                    
                /> */}
            </Widget>
            <ResetPasswordModal />
            <VitalCardModal />
            <InsuranceCardModal />
            {/* <InsuranceCardModal/> */}
        </Layout>
    );
};

export default Index;