import React, { useEffect } from 'react'
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import {  DataRequestAction } from 'redux/actions/CommonHttp'
import { Spin } from 'antd'
import IntlMessages from 'util/IntlMessages'
const VitalCardModal = () => {


    const dispatch = useDispatch();
    const curdProps = useSelector(state => state.CrudR)
    const { Vital_Card_Modal, initialValues } = curdProps

    const pageProps = useSelector(state => state.pageInfo)
    const { cardLink, startLoader } = pageProps


    const fetchImage = () => {
        
        if (Vital_Card_Modal && initialValues) {
  
            dispatch(DataRequestAction('POST', 'getTempLink', 'getTempLink', { 'fileName': initialValues }, 'StartLoader'))
        }
    }
    useEffect(fetchImage, [Vital_Card_Modal])


    return (
        <div>
            <SimpleModal
                width={500}
                modalFor="Vital_Card_Modal"
                visible={Vital_Card_Modal ? Vital_Card_Modal : false}>
                {startLoader ? <Spin /> : initialValues ? <img src={cardLink} alt=""  className="gx-mt-4" /> : ''}
                {!initialValues  && <span><IntlMessages id="imageModal.noDataFound" /></span>}

            </SimpleModal>
        </div>
    )
}

export default VitalCardModal
