import React, { useEffect } from "react";
import Widget from "components/Widget";
import AllProductList from "components/Table/List";
import IntlMessages from "util/IntlMessages";
import TableAction from "components/Table/Actions";
import Layout from "components/LayoutForPortal";
import SelectFilter from "components/Filters/SelectFilter";
import SearchBox from "components/Filters/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { DataRequestAction } from "redux/actions/CommonHttp";
import { renderStatus } from "utils/commonUseFunction";
import dummy from "assets/images/user-dummy.png";
import moment from "moment";
import EditProduct from "./components/EditProduct";
import { DataGetAction } from "redux/actions/CommonHttp";
import { intlPlaceholder } from "util/intlPlaceholder";
import DeleteConfirm from "components/Modal/DeleteConfirm";
import PharmacyNameModal from "../PharmacyNameModal";
const Index = () => {
  const dispatch = useDispatch();

  const fetchList = () => {
    dispatch(
      DataGetAction(
        "getCategory",
        "getCategory",
        { query: "all" },
        "",
        "",
        "allCategories"
      )
    );
  };

  useEffect(fetchList, []);

  const pageProps = useSelector((state) => state.pageInfo);
  const { allCategories } = pageProps;

  const renderPharmacyNames = (pharmacyNames) => {
    let pharmacies =
      pharmacyNames &&
      pharmacyNames.length > 0 &&
      pharmacyNames.map((item) => item.pharmacyName);
    return (
      <div>
        <p >{pharmacies[0]}</p>
        {pharmacies && pharmacies.length > 1 && <p style={{color : 'blue', cursor : 'pointer'}}
        onClick={()=>{
            dispatch({
                type: "Show_Modal",
                payload: pharmacies,
                ModalName: "Pharmacy_Name_Modal",
              });
        }}
        >+ {pharmacies.length -1}  more</p>}
      </div>
    );
  };

  const columns = [
    {
      title: <IntlMessages id="product.id" />,
      render: (record) => <span>{record.productId}</span>,
      key: "id",
    },

    {
      title: <IntlMessages id="product.productName" />,
      render: (record) => {
        return (
          <div className="gx-d-flex gx-align-items-center">
            {record.productImageUrl ? (
              <img
                src={record.productImageUrl}
                width="30"
                style={{ borderRadius: "50%" }}
                height="30"
                alt="productImage"
              />
            ) : (
              <img
                src={dummy}
                width="30"
                style={{ borderRadius: "50%" }}
                height="30"
                alt="productImage"
              />
            )}
            <span className="gx-ml-2">
              {record.productName && record.productName}
            </span>
          </div>
        );
      },
      key: "doctorName",
    },

    {
      title: <IntlMessages id="product.category" />,
      render: (record) => <span>{record.categoryName}</span>,
      key: "category",
    },
    {
      title: <IntlMessages id="product.manufacturer" />,
      render: (record) => <span>{record.manufacturer}</span>,
      key: "manufacturer",
    },
    {
      title: <IntlMessages id="product.pharmacyName" />,
      render: (record) => renderPharmacyNames(record.pharmacyInfo),
      key: "pharmacyName",
    },
    {
      title: <IntlMessages id="product.price" />,
      render: (record) => <span>€{record.price && record.price} </span>,
      key: "price",
    },

    {
      title: <IntlMessages id="product.status" />,
      render: (record) => (
        <span className="gx-text-capitalize">
          {renderStatus(record.status)}
        </span>
      ),
      key: "location",
    },
    {
      title: "",
      render: (record) => <TableAction> {renderActions(record)}</TableAction>,
      key: "action",
    },
  ];
  const changeUserStatus = (_id, status) => {
    dispatch(
      DataRequestAction(
        "PUT",
        "updateProductStatus",
        "EditRecord",
        { _id: _id, status: status },
        "updatingUserStatus",
        false,
        "productList"
      )
    );
  };

  const renderEdit = (record) => {
    record.dateAndTime = moment(record.dateAndTime);
    dispatch({
      type: "Show_Modal",
      payload: record,
      ModalName: "Edit_Product_Modal",
    });
  };
  const renderActions = (record) => {
    return (
      <div className="gx-d-flex gx-flex-column">
        {record.status !== "reject" && record.status !== "block" && (
          <span
            className="action-link first gx-link"
            onClick={() => renderEdit(record)}
          >
            <IntlMessages id="approvedProduct.actions.edit" />
          </span>
        )}
        {record.status === "approved" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => changeUserStatus(record._id, "block")}
          >
            <IntlMessages id="approvedProduct.actions.block" />
          </span>
        )}
        {record.status === "block" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => changeUserStatus(record._id, "approved")}
          >
            <IntlMessages id="approvedProduct.actions.unBlock" />
          </span>
        )}
        {record.status === "reject" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => changeUserStatus(record._id, "approved")}
          >
            <IntlMessages id="approvedProduct.actions.approve" />
          </span>
        )}
        {record.status === "deleted" && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => changeUserStatus(record._id, "approved")}
          >
            <IntlMessages id="approvedProduct.actions.restore" />
          </span>
        )}
        {/* {(record.status === "approved") && <span style={{ cursor: 'pointer' }} onClick={() => changeUserStatus(record._id, 'delete')}><IntlMessages id="approvedProduct.actions.approve" /></span>} */}

        <DeleteConfirm
          type="updateProductStatus"
          recordName={"productList"}
          method="PUT"
          selectedItem={{ ...record, status: "deleted" }}
          apiRequest="EditRecord"
          buttonName="Yes"
          oKText="Yes"
          title={intlPlaceholder("approvedProduct.actions.delete_message")}
          content=""
          Icon={
            <span style={{ fontSize: "14px" }}>
              <IntlMessages id="approvedProduct.actions.delete" />
            </span>
          }
        />
      </div>
    );
  };

  return (
    <Layout>
      <Widget
        styleName="mainTable"
        title={
          <div className="gx-mb-3">
            <h4
              className="gx-mb-0 gx-font-gilory-bold"
              style={{ color: "#14244E" }}
            >
              <IntlMessages id="approvedProduct.pageTitle" />
            </h4>
          </div>
        }
        extra={
          <>
            <div
              className="gx-d-flex gx-mb-0"
              style={{ flexWrap: "wrap", gap: "10px" }}
            >
              {/* <div style={{ width: '240px', marginRight: '12px' }}>
                                <DateFilter placeholder={[intlPlaceholder('approvedProduct.filter.startDate'),intlPlaceholder('approvedProduct.filter.endDate')]} apiName="getProduct" requestType="FetchRecord" recordName="productList" />
                            </div> */}
              <div style={{ height: "100%" }}>
                <SelectFilter
                  isShowingAll={true}
                  className="select-filter"
                  apiName="getProduct"
                  searchKey="category"
                  recordName="productList"
                  options={allCategories}
                  placeholder={
                    <IntlMessages id="approvedProduct.filter.filterByCategory" />
                  }
                  ItemValue="_id"
                  label="name"
                />
              </div>
              <div style={{ height: "100%" }}>
                <SelectFilter
                  isShowingAll={true}
                  className="select-filter"
                  apiName="getProduct"
                  searchKey="status"
                  recordName="productList"
                  options={[
                    {
                      status: "approved",
                      label: (
                        <IntlMessages id="approvedProduct.filter.filterByStatus.approved" />
                      ),
                    },
                    {
                      status: "block",
                      label: (
                        <IntlMessages id="approvedProduct.filter.filterByStatus.blocked" />
                      ),
                    },
                    {
                      status: "pending",
                      label: (
                        <IntlMessages id="approvedProduct.filter.filterByStatus.pending" />
                      ),
                    },
                    {
                      status: "deleted",
                      label: (
                        <IntlMessages id="approvedProduct.filter.filterByStatus.deleted" />
                      ),
                    },
                  ]}
                  placeholder={
                    <IntlMessages id="approvedProduct.filter.filterByStatus" />
                  }
                  ItemValue="status"
                  label="label"
                />
              </div>
              <div style={{ width: "220px" }}>
                <SearchBox
                  placeholder={intlPlaceholder(
                    "approvedProduct.filter.ProductPharmacyId"
                  )}
                  recordName="productList"
                  apiName="getProduct"
                  requestType="FetchRecord"
                />
              </div>
            </div>
          </>
        }
      >
        <AllProductList
          scroll={{ x: 1400 }}
          recordName="productList"
          apiName="getProduct"
          columns={columns}
        />
      </Widget>
      <EditProduct />
      <PharmacyNameModal/>
    </Layout>
  );
};

export default Index;
