import React, { useEffect } from 'react'
import { Table, message } from 'antd';
import { DataGetAction } from 'redux/actions/CommonHttp'
import { useDispatch, useSelector } from 'react-redux'



const Index = ({
    columns,
    scroll,
    pagination,
    apiName,
    callOnCondition,
    customQuery = {},
    recordName,
    tempRecord,
    styleName,
    localeEmptyText
}) => {

    const dispatch = useDispatch()
    const CurdProps = useSelector(state => state.CrudR)

   
    const fetchList = () => {
       dispatch(DataGetAction(apiName, 'FetchRecord', { query: 'all', ...customQuery }, 'StartSpinner', '', recordName))
    
    }
       
    useEffect(fetchList, [callOnCondition])


    const {  success, error, DeleteError } = CurdProps;

 

    if (success) {
        if(CurdProps.message !== ''){
            message.success(CurdProps.message)
        }
       
        dispatch({
            type: 'ResetAll_State'
        })
    }

    if (error) {
        if(CurdProps.message !== ''){
            message.error(CurdProps.message)
        }
        
        dispatch({
            type: 'ResetAll_State'
        })
    }
    if (DeleteError) {
        message.error(CurdProps.message)
        dispatch({
            type: 'ResetAll_State'
        })
    }


    return <Table
        className={`BasicTable gx-table-responsive ${styleName}`}
        columns={columns}
        dataSource={tempRecord ? tempRecord : CurdProps[recordName]}
        scroll={scroll}
        pagination={pagination}
        locale={{
            emptyText : localeEmptyText || 'No Data'
        }}
    />
}

export default Index
