import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Select, Button, TimePicker,InputNumber } from 'antd';
import PhoneInput from 'react-phone-input-2'
import IntlMessages from 'util/IntlMessages'
import AvatarUploader from 'components/AvatarUploader'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux'
import { DataRequestAction } from 'redux/actions/CommonHttp';
import querystring from 'querystring';
// import jsonp from 'fetch-jsonp'
const { Option } = Select;


const PharmacyForm = ({ setPhotoState, photoList, action, visibility, initialValues, SelectedAddress }) => {
    const [customKey, setCustomKey] = useState([])
    const [addressData, setAddressData] = useState([])
    const dispatch = useDispatch()
    const [phoneNumber, setPhoneNumber] = useState();


    const fetchTeamUsers = () => {

        if (action === 'edit' && initialValues && initialValues.workingHour) {
            let arr = []
            initialValues.workingHour.forEach((item, index) => {
                if (item.to && item.from) {
                    arr.push(index)
                }
            })

            setCustomKey(arr)

        }
    }
    useEffect(fetchTeamUsers, [action])
    const pageProps = useSelector(state => state.pageInfo)
    const { alreadyExist } = pageProps



    const CurdProps = useSelector(state => state.CrudR)
    const { success } = CurdProps;
    if (success) {
        setCustomKey([])
        dispatch({
            type: 'ResetAll_State'
        })
    }

    let days = [
        { value: 'monday', label: <IntlMessages id="pharmacy.addEditForm.monday" />, disabled: false },
        { value: 'tuesday', label: <IntlMessages id="pharmacy.addEditForm.tuesday" />, disabled: false },
        { value: 'wednesday', label: <IntlMessages id="pharmacy.addEditForm.wednesday" />, disabled: false },
        { value: 'thursday', label: <IntlMessages id="pharmacy.addEditForm.thursday" />, disabled: false },
        { value: 'friday', label: <IntlMessages id="pharmacy.addEditForm.friday" />, disabled: false },
        { value: 'saturday', label: <IntlMessages id="pharmacy.addEditForm.saturday" />, disabled: false },
        { value: 'sunday', label: <IntlMessages id="pharmacy.addEditForm.sunday" />, disabled: false },
    ]



    const handleChange = (value, key) => {


        if (!customKey.length && value === 'custom') {
            setCustomKey([parseInt(key.key)])
        }
        else if (customKey.length && value === 'custom') {
            let arr = [...customKey]
            arr.push(key.key)
            setCustomKey(arr)
        }
        else if (value !== 'custom') {
            if (customKey.includes(parseInt(key.key))) {

                let newArray = []
                customKey && customKey.forEach(item => {
                    if (item !== parseInt(key.key)) {
                        newArray.push(item)

                    }
                })

                setCustomKey(newArray)
            }
        }
    }

    const renderDays = () => {
        return days && days.map(item => {
            return <Option value={item.value} key={item.value} disabled={item.disabled}>{item.label}</Option>
        })
    }


    // if (alreadyExist) {
    //     form.setFields([{
    //         'email': {
    //             errors: [new Error('forbid ha')],
    //         },
    //     }]);
    // }

    const checkEmailExit = (e) => (e) => {

        if (visibility) {
            dispatch(DataRequestAction('POST', 'emailCheck', 'emailCheck', { 'email': e.target.value }, 'loading', ''))
        }

    }

    const fetchAddress = (query, callback) => {
        const str = querystring.encode({
            code: 'utf-8',
            q: query,
            autocomplete: 1
        });
        fetch(`https://api-adresse.data.gouv.fr/search/?${str}`).then((response) => {
            response.json().then((response) => {

                let data = [];
                if (response.features && response.features.length > 0) {

                    response.features.forEach((Item, index) => {
                        data.push({
                            value: index,
                            text: Item.properties.label,
                            record: Item
                        });
                    })
                    callback(data)
                } else {
                    callback(data)
                }
            })
        }).catch(err => {

            console.log("======= err =======", err)
        })
    }


    const handleSearch = value => {
        console.log("====== value chagnes =======",value);

        if (value) {
            fetchAddress(value, data => setAddressData(data));
        } else {
            setAddressData([]);
        }
    };

    const handleChangeAddress = value => {

        console.log("======= Value handleChangeAddress======",value);
        dispatch(DataRequestAction('POST', 'emailAddress', 'emailAddress', { 'address': value }, 'loading', ''))

        SelectedAddress(addressData[value].record)
    }

    const renderOption = () => {
        if (addressData && addressData.length > 0) {
            return addressData.map(Item => {
                return <Option value={Item.value}>{Item.text}</Option>
            })
        }
    }
    /* Limit the number input box to only enter integers */
    const limitNumber = value => {
        if (typeof value === 'string') {
            return !isNaN(Number(value)) ? value.replace(/^(0+)|[^\d]/g, '') : ''
        } else if (typeof value === 'number') {
            return !isNaN(value) ? String(value).replace(/^(0+)|[^\d]/g, '') : ''
        } else {
            return ''
        }
    }
    return (
        <div>
            <Row className="gx-d-flex gx-flex-row">
                <Col xl={16} lg={16} md={18} sm={24} xs={24}>
                    <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                            <Form.Item
                                name="pharmacyName"
                                label={<IntlMessages id="pharmacy.addEditForm.pharmacyName" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pharmacy.addEditForm.pharmacyName" />
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="fullName"
                                label={<IntlMessages id="pharmacy.addEditForm.ownerName" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pharmacy.addEditForm.ownerName" />
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                name="email"
                                className="lower-case-email"
                                label={<IntlMessages id="pharmacy.addEditForm.email" />}
                                validateStatus={alreadyExist ? 'error' : ''}
                                help={alreadyExist ? 'email already exist' : ''}
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: <IntlMessages id="pharmacy.addEditForm.email" />
                                    },

                                ]}

                            >
                                <Input onBlur={checkEmailExit()} />

                            </Form.Item>
                        </Col>
                    </Row>

                    {action !== 'edit' && <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label={<IntlMessages id="pharmacy.addEditForm.password" />}
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pharmacy.addEditForm.password" />,
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label={<IntlMessages id="pharmacy.addEditForm.confirmPassword" />}
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="pharmacy.addEditForm.passwordNotMatch" />,
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(<IntlMessages id="pharmacy.addEditForm.passwordNotMatch" />);
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>}





                    <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                            <Form.Item
                                label={<IntlMessages id="pharmacy.addEditForm.phoneNum" />}
                                name="phoneNumber"
                                style={{
                                    marginLeft: '0px',
                                    marginRight: '0px'
                                }}
                                rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.phoneNum" /> }]}
                            >
                                <PhoneInput
                                    inputStyle={{
                                        width: '100%'
                                    }}
                                    country={'fr'}
                                    value={phoneNumber}
                                    onChange={phone => setPhoneNumber({ phone })}
                                />
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                            < Form.Item
                                name="address"
                                label={< IntlMessages id="pharmacy.addEditForm.address" />} rules={
                                    [
                                        {
                                            required: true,
                                            message: <IntlMessages id="pharmacy.addEditForm.address" />
                                        },
                                    ]}
                            >
                                <Select
                                    showSearch

                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleChangeAddress}
                                    notFoundContent={null}
                                >
                                    {renderOption()}
                                </Select>
                            </ Form.Item>
                        </Col>

                    </Row>

                </Col>
                <Col xl={8} lg={8} md={6} sm={24} xs={24}>
                    <Form.Item
                        name="photo"
                    >
                        <AvatarUploader setPhotoState={setPhotoState} photoList={photoList} text={<IntlMessages id="pharmacy.addEditForm.uploadImage" />} />
                    </Form.Item>
                </Col>
            </Row>

            <hr />

            <Row className="gx-mx-0 gx-d-flex gx-flex-row">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <label htmlFor="" className="gx-mb-2 gx-d-inline-block"><IntlMessages id="pharmacy.addEditForm.workingHours" /></label>
                    <Form.List name="workingHour" rules={[
                        {
                            validator: async (_, workingHour) => {

                                if (workingHour === undefined || workingHour.length < 1) {
                                    return Promise.reject(new Error('please select working hours'));
                                }

                            },
                        },
                    ]} >
                        {(fields, { add, remove }, { errors }) => (
                            <> {fields && fields.forEach(item => {
                                customKey && customKey.forEach(customItem => {

                                    if (parseInt(item.fieldKey) === parseInt(customItem)) {
                                        item.showField = true
                                    }
                                })
                            })}
                                {fields.map(({ key, name, fieldKey, showField, ...restField }) => (

                                    <>
                                        <Row className="gx-d-flex gx-flex-row">

                                            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'day']}
                                                    fieldKey={[fieldKey, 'day']}
                                                    rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.selectDay" /> }]}
                                                >
                                                    <Select placeholder={<IntlMessages id="pharmacy.addEditForm.selectDay" />} >
                                                        {renderDays()}

                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'time']}
                                                    fieldKey={[fieldKey, 'time']}
                                                    rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.selectTime" /> }]}
                                                >
                                                    <Select placeholder={<IntlMessages id="pharmacy.addEditForm.selectTime" />} onChange={(value, key) => { handleChange(value, key) }}>
                                                        <Option value="24" key={key}><IntlMessages id="pharmacy.addEditForm.24Hour" /></Option>
                                                        <Option value="off" key={key}><IntlMessages id="pharmacy.addEditForm.off" /></Option>
                                                        <Option value="custom" key={key}><IntlMessages id="pharmacy.addEditForm.custom" /></Option>

                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            {showField === true && <>
                                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-px-0">

                                                    <Form.Item
                                                        {...restField}
                                                        label="To"
                                                        name={[name, 'to']}
                                                        fieldKey={[fieldKey, 'to']}
                                                        rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.selectTime" /> }]}
                                                    >
                                                        <TimePicker format={'HH:mm'} className="gx-w-100" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
                                                    <Form.Item
                                                        {...restField}
                                                        label="From"
                                                        name={[name, 'from']}
                                                        fieldKey={[fieldKey, 'from']}
                                                        rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.selectTime" /> }]}
                                                    >
                                                        <TimePicker format={'HH:mm'} className="gx-w-100" />
                                                    </Form.Item>
                                                </Col>
                                            </>
                                            }

                                            <MinusCircleOutlined style={{ position: 'absolute', right: 0 }} onClick={() => remove(name)} />
                                        </Row>

                                    </>
                                ))}
                                <Form.Item className="gx-mb-0">
                                    <Button type="dashed" className="gx-pt-0 gx-mb-0" onClick={() => add()} block icon={<PlusOutlined />}>
                                        <IntlMessages id="pharmacy.addEditForm.addTimeAndDate" />
                                    </Button>
                                </Form.Item>
                                <Form.ErrorList errors={errors} />
                            </>
                        )}
                    </Form.List>
                </Col>

               
            </Row>
            <Row className="gx-mx-0 gx-d-flex gx-flex-row gx-mt-3">
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                        name="socialSecurity"
                        label={<IntlMessages id="pharmacy.addEditForm.socialSecurity" />}
                        rules={[
                            {
                                required: true,
                                message: <IntlMessages id="pharmacy.addEditForm.socialSecurity" />
                            },
                        ]}
                    >
                        <Select  >
                            <Option value="accepted" ><IntlMessages id="pharmacy.addEditForm.accepted" /></Option>
                            <Option value="notAccepted" ><IntlMessages id="pharmacy.addEditForm.notAccepted" /></Option>


                        </Select>
                    </Form.Item>
                </Col>
                <Col xl={12} lg={12} md={12} sm={24} xs={24}>

                    <Form.Item
                        label={<IntlMessages id="pharmacy.addEditForm.estimatedDeliveryTime" />}
                        name="estimateDeliveryTime"
                        style={{
                            marginLeft: '0px',
                            marginRight: '0px'
                        }}
                        rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.estimatedDeliveryTime" /> }]}
                    >
                        <InputNumber formatter={limitNumber} style={{width : '100%'}} />
                    </Form.Item>
                </Col>
            </Row>

        </div>
    )
}

export default PharmacyForm




