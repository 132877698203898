import React, { useEffect,useState } from 'react'
import { useSelector } from 'react-redux'
import FormModal from "../../../../components/Modal/EditFormModal";
import IntlMessages from '../../../../util/IntlMessages'
import PharmacyForm from './PharmacyForm'
import {  useDispatch } from 'react-redux'

const EditUser = () => {

    const dispatch = useDispatch()
    
    let initialState = {
        loading: false,
        imageUrl: '',
    }
    
    const [selectedAddress, SelectedAddress] = useState(null)
    const curdProps = useSelector(state => state.CrudR)
    const { Edit_Pharmacy_Modal, Loader, initialValues } = curdProps

    
    const [photoList, setPhotoState] = useState(initialState)

    const reset = () => {
        if(Edit_Pharmacy_Modal){
            setPhotoState({
                loading: false,
                imageUrl:initialValues && initialValues.profileImageUrl,
            })
            dispatch({
                type : 'reset_alreadyExist'
            })
        }
        
      }
    
    useEffect(reset, [Edit_Pharmacy_Modal])
    return (
        <div>


            <FormModal
                modalTitle={<p className="gx-text-primary"><IntlMessages id="pharmacy.editModal.title" />  </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="pharmacy.editModal.save" />}
                cancelButtonText={<IntlMessages id="pharmacy.editModal.cancel" />}
                editApiRequest="EditRecord"
                apiLoader="StartSpinner"
                editApiMethod="PUT"
                editApiName="editPharmacy"
                ModalName="Edit_Pharmacy_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Edit_Pharmacy_Modal ? Edit_Pharmacy_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                initialValues={initialValues}
                width={750}
                className="doctor-profile-modal"
                extraFieldName="profileImageUrl"
                extraFieldValue={photoList.imageUrl}
                extraFieldAddress="pharmacyFullAddress"
                extraFieldValueAddress = {selectedAddress ? selectedAddress : initialValues && initialValues.pharmacyFullAddress}
                recordName="pharmacyList"
            >
                

                 <PharmacyForm
                    action="edit"
                    initialValues={initialValues}
                    
                    setPhotoState={setPhotoState} 
                    visibility={Edit_Pharmacy_Modal} 
                    photoList={photoList} 
                    SelectedAddress={SelectedAddress}
                    />
            </FormModal>
        </div>

    )
}

export default EditUser
