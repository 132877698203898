const ROOT_URL = process.env.REACT_APP_ROOT_URL;

export const DataRequestAction = (method, type, data, redirectUrl, Loading = "LOADING", showNotification = true) => {
    return function (dispatch) {
        RequestProgress(dispatch, `${Loading}_START`);
        const token = localStorage.getItem('token');
        try {
            fetch(`${ROOT_URL}/${type}`, {
                method: method,
                headers: new Headers({
                    "Content-Type": "application/json",
                    'Authorization': token,
                    'APIType':"web"
                }),
                body: JSON.stringify(data)
            }).then((response) => {
                response.json().then((response) => {
                    if (response.status === "ERROR") {
                        RequestProgress(dispatch, `${Loading}_STOP`);
                        RequestFail(dispatch, type, response.message, "error");

                        if (showNotification) {
                            addNotification(dispatch, response.message, "error");
                        }
                    } else {
                        RequestSuccess(dispatch, type, response, data);
                        if (showNotification) {
                            addNotification(dispatch, response.message, "success", redirectUrl);
                        }
                    }
                })

            }).catch((err) => {
                RequestProgress(dispatch, `${Loading}_STOP`);
                RequestFail(dispatch, type, "", "error");

                if (showNotification) {
                    addNotification(dispatch, err, "error");
                }

            })
        } catch (error) {

            RequestProgress(dispatch, `${Loading}_STOP`);
            RequestFail(dispatch, type, "", "error");

            if (showNotification) {
                addNotification(dispatch, error, "error");
            }
        }
    };
};

export const FormDataRequestAction = (type,requestType, data,  Loading = "LOADING",ModalName,recordName) => {
    return function (dispatch) {
        RequestProgress(dispatch, `${Loading}_START`);
        const token = localStorage.getItem('token');
        try {
            console.log("===== data.file =====",);
            const dataObj = new FormData();
            let file  = null
            if(data.file && data.file[0].originFileObj){
                file = data.file[0].originFileObj
            }
            if (data.file) dataObj.append("file", file);
           
            dataObj.append("data", JSON.stringify({categoryId:data.categoryId, pharmacyId:data.pharmacyId}));
            
            
            fetch(`${ROOT_URL}/${type}`, {
                method: "POST",
                headers: new Headers({
                    'Authorization': token,
                }),
                body: dataObj
            }).then((response) => {
                if (response.status === 401) {
                    RequestUnAuthorized(dispatch, `showUnauthorized`);
                } 
                response.json().then((response) => {

                    if (response.status === 409) {
                        RequestFail(dispatch, 'AlreadyExist', response.message, "error");
                        
                    } 
                  
                    else if (response.status === "ERROR") {
                        RequestFail(dispatch, requestType, response.message, "error");
                        // RequestModalClose(dispatch, ModalName)

                    } else {
                        RequestSuccess(dispatch, requestType, response, data,recordName);
                        RequestModalClose(dispatch, ModalName)
                    }
                })

            }).catch((err) => {
                RequestProgress(dispatch, `${Loading}_STOP`);
                RequestFail(dispatch, type, "", "error");

               

            })
        } catch (error) {
            RequestProgress(dispatch, `${Loading}_STOP`);
            RequestFail(dispatch, type, "", "error");

          
        }
    };
};

export const DataGetAction = (type, data, Loading = "Nothing") => {
    return function (dispatch) {
        RequestProgress(dispatch, `${Loading}_START`);
        const token = localStorage.getItem('token');
        let param = "";
        if (data) {
            param = `/${JSON.stringify(data)}`
        }
        let headers = {
            "Content-Type": "application/json",
            'Authorization': token,
            'APIType':"web"

        };

        try {
            fetch(`${ROOT_URL}/${type}${param}`, { headers })
                .then((response) => {
                    if (response.status === 401) {
                        RequestUnAuthorized(dispatch, `showUnauthorized`);
                    } 
                    else{
                    response.json().then((response) => {
                        RequestProgress(dispatch, `${Loading}_STOP`);
                        RequestSuccess(dispatch, type, response, data)
                    })
                }
                }).catch(err => {
                    RequestProgress(dispatch, `${Loading}_STOP`);
                    RequestFail(dispatch, type, "Request Fail outer", err);
                })
        } catch (error) {
            RequestProgress(dispatch, `${Loading}_STOP`);
            RequestFail(dispatch, type, "Request Fail", error);
        }
    };
};

export const DataGetActionAPI = (API, type, data, Loading = "Nothing") => {
    return function (dispatch) {
        RequestProgress(dispatch, `${Loading}_START`);
        const token = localStorage.getItem('token');
        let param = "";
        if (data) {
            param = `/${JSON.stringify(data)}`
        }
        let headers = {
            "Content-Type": "application/json",
            'Authorization': token,
        };

        try {
            fetch(`${ROOT_URL}/${API}${param}`, { headers })
                .then((response) => {
                    response.json().then((response) => {
                        RequestProgress(dispatch, `${Loading}_STOP`);
                        RequestSuccess(dispatch, type, response, data)
                    })
                }).catch(err => {
                    RequestProgress(dispatch, `${Loading}_STOP`);
                    RequestFail(dispatch, type, "Request Fail outer", err);
                })
        } catch (error) {
            RequestProgress(dispatch, `${Loading}_STOP`);
            RequestFail(dispatch, type, "Request Fail", error);
        }
    };
};
function RequestModalClose(dispatch, ModalName) {
  
    if (ModalName !== "Nothing") {
        dispatch({
            type: 'Hide_Modal',
            payload: null,
            ModalName: ModalName
        })
    }
}
function RequestUnAuthorized(dispatch, type) {
    dispatch({
        type: `UN_AUTHORIZED`,
    });
}
export const updateAllState = (type) => {
    return function (dispatch) {
        dispatch({
            type: `${type}_UPDATE`,
        });
    }
}

export const DataSelectUser = (type, Item) => {
    return function (dispatch) {
        dispatch({
            type: `${type}_SUCCESS`,
            payload: Item
        });
    }
}

export const restNotificationState = () => {
    return function (dispatch) {
        dispatch({
            type: `REST_NOTIFICATION`,
        });
    }
}


function addNotification(dispatch, message, level, redirectUrl = "") {
    dispatch({
        type: 'SHOW_NOTIFICATION',
        message,
        level,
        redirectUrl
    });
}


function RequestFail(dispatch, type, message, error) {

    dispatch({
        type: `${type}_FAILURE`,
        payload: message,
        error
    });
}
function RequestSuccess(dispatch, type, data, reqData,recordName) {

    dispatch({
        type: `${type}_SUCCESS`,
        payload: data,
        recordName : recordName,
        reqData
    });
}

function RequestProgress(dispatch, type) {
    dispatch({
        type: `${type}`,
    });
}

export const SocketResponseState = (type, payload) => {
    return function (dispatch) {
        dispatch({
            type: `${type}_SUCCESS`,
            payload: payload
        });
    }
}