import React from 'react'
import { DataRequestAction } from "redux/actions/CommonHttp";
import SimpleModal from "components/Modal/SimpleModal";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from 'util/IntlMessages'
import AvatarUploader from 'components/AvatarUploader'
import { Form, Input, Row, Col, Button, Spin, InputNumber, Select } from 'antd';

const EditProfile = ({ setPhotoState, photoList }) => {


    const settingsProps = useSelector(state => state.auth)
    const { settingsData, updatingProfile } = settingsProps

    const curdProps = useSelector(state => state.CrudR)
    const { EditProfile_Modal } = curdProps
    const dispatch = useDispatch();

    const toggleEditProfile = () => {
        dispatch({
            ModalName: 'EditProfile_Modal',
            type: 'Show_Modal',
            payload: null
        })
    }

    const updateProfle = values => {
        values.profileImageUrl = photoList.imageUrl
        console.log('=========values=========',values)
        dispatch(DataRequestAction('PUT', 'updateProfile', 'updateProfile', values, 'updatingProle', 'EditProfile_Modal'))
    };

    /* Limit the number input box to only enter integers */
    const limitNumber = value => {
        if (typeof value === 'string') {
            return !isNaN(Number(value)) ? value.replace(/^(0+)|[^\d]/g, '') : ''
        } else if (typeof value === 'number') {
            return !isNaN(value) ? String(value).replace(/^(0+)|[^\d]/g, '') : ''
        } else {
            return ''
        }
    }

    const options = [
        {
            value: 'ziticity',
            name: 'Ziticity'
        },
        {
            value: 'stuart',
            name: 'Stuart'
        },
        {
            value: 'k2express',
            name: 'K2 Express'
        },
    ]

    return (
        <div>

            <span style={{ color: 'grey' }} className="icon icon-edit gx-link" onClick={() => toggleEditProfile()}></span>
            <SimpleModal
                width={500}
                title={<span style={{ color: '#041649' }} className="gx-font-sans-bold">{<IntlMessages id="settings.editModal.updateProfile" />} </span>}
                modalFor="EditProfile_Modal"
                visible={EditProfile_Modal ? EditProfile_Modal : false}>

                <span></span>
                <Form
                    layout="vertical"
                    name="editProfile"
                    onFinish={updateProfle}
                    initialValues={settingsData && settingsData}
                >



                    <Row className="gx-d-flex gx-flex-row gx-align-items-center">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">

                            <Form.Item
                                name="fullName"
                                label={<IntlMessages id="settings.editModal.fullName" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="settings.editModal.fullName_message" />,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>


                            <Form.Item
                                name="email"
                                className="lower-case-email"
                                label={<IntlMessages id="settings.editModal.email" />}
                                rules={[
                                    {
                                        type: 'email',
                                        required: true,
                                        message: <IntlMessages id="settings.editModal.email_message" />,
                                    },
                                ]}

                            >
                                <Input disabled />
                            </Form.Item>

                            <Form.Item
                                name="phoneNumber"
                                label={<IntlMessages id="settings.editModal.phone" />}
                                rules={[
                                    {
                                        required: true,
                                        message: <IntlMessages id="settings.editModal.phone_message" />,
                                    },
                                ]}

                            >
                                <Input />
                            </Form.Item>




                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
                            <Form.Item
                                name="photo"
                            >
                                <AvatarUploader setPhotoState={setPhotoState} photoList={photoList} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row className="gx-d-flex gx-flex-row gx-align-items-center">
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pl-0">
                            <Form.Item
                                label={<IntlMessages id="pharmacy.addEditForm.deliveryCost" />}
                                name="deliveryCost"
                                style={{
                                    marginLeft: '0px',
                                    marginRight: '0px'
                                }}
                                rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.deliveryCost_message" /> }]}
                            >
                                <InputNumber style={{ width: '100%' }} precision={2} />
                            </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="gx-pr-0">
                            <Form.Item
                                label={<IntlMessages id="pharmacy.addEditForm.nearAreaRadius" />}
                                name="nearAreaRadius"
                                style={{
                                    marginLeft: '0px',
                                    marginRight: '0px'
                                }}
                                rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.nearAreaRadius_message" /> }]}
                            >
                                <InputNumber formatter={limitNumber} style={{ width: '100%' }} placeholder="km" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="gx-d-flex gx-flex-row gx-align-items-center">
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
                            <Form.Item
                                label={<IntlMessages id="pharmacy.addEditForm.deliverSetting" />}
                                name="deliverSetting"
                                style={{
                                    marginLeft: '0px',
                                    marginRight: '0px'
                                }}
                                rules={[{ required: true, message: <IntlMessages id="pharmacy.addEditForm.deliverSetting_message" /> }]}
                            >
                                <Select   mode="multiple" >

                                    {options && options.map(item => {

                                        return <Select.Option value={item.value}>{item.name}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>



                    <Row>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24} className="gx-px-0">
                            <div className="gx-flex gx-flex-row gx-align-items-center gx-justify-content-end ">

                                <Form.Item className="gx-mb-0">
                                    <Button type="default" className="gx-pt-0 gx-mb-0 gx-mr-2" htmlType="button" onClick={() =>  dispatch({
            type: "Hide_Modal",
            ModalName: 'EditProfile_Modal',
            payload: null,
          })}>
                                        <IntlMessages id="settings.editModal.cancel" />
                                    </Button>
                                </Form.Item>
                                <Form.Item className="gx-mb-0">
                                    {updatingProfile && <Spin>   <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="button" disbaled>
                                        <IntlMessages id="settings.editModal.save" />
                                    </Button></Spin>}

                                    {!updatingProfile && <Button type="primary" className="gx-pt-0 gx-mb-0" htmlType="submit">
                                        <IntlMessages id="settings.editModal.save" />
                                    </Button>}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SimpleModal>
        </div>
    )
}

export default EditProfile
