import React, { useState, useEffect } from 'react'
import { Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux'
import FormModal from "../../../../components/Modal/FormModal";
import IntlMessages from '../../../../util/IntlMessages'
import PharmacyForm from './PharmacyForm'


const AddNewPharmacy = () => {


    
    let initialState = {
        loading: false,
        imageUrl: '',
    }
    const [photoList, setPhotoState] = useState(initialState)
    const [selectedAddress, SelectedAddress] = useState(null)
    const dispatch = useDispatch()
    const curdProps = useSelector(state => state.CrudR)
    const { Add_Pharmacy_Modal, Loader } = curdProps

    const reset = () => {
        if (Add_Pharmacy_Modal) {
            dispatch({
                type: 'reset_alreadyExist'
            })
            setPhotoState({
                loading: false,
                imageUrl: '',
            })
        }
        
    }

    useEffect(reset, [Add_Pharmacy_Modal])


    return (
        <div>
            <Button
                className='gx-pt-0'
                type="primary"
                onClick={() => {
                    dispatch({
                        type: 'Show_Modal',
                        payload: null,
                        ModalName: "Add_Pharmacy_Modal"
                    })
                }}
            >
                <IntlMessages id="pharmacy.addNewButton.label" />
            </Button>
            <FormModal
                modalTitle={<p className="gx-text-primary"> <IntlMessages id="pharmacy.addModal.title" /> </p>}
                basicButtonTitle="Reset Password"
                submitButtonText={<IntlMessages id="pharmacy.addModal.save" />}
                cancelButtonText={<IntlMessages id="pharmacy.addModal.cancel" />}
                apiMethod="POST"
                addApiName="addPharmacy"
                addApiRequest="AddRecord"
                apiLoader="StartSpinner"
                ModalName="Add_Pharmacy_Modal"
                confirmLoading={Loader}
                ModalType="Hide_Modal"
                visible={Add_Pharmacy_Modal ? Add_Pharmacy_Modal : false}
                buttonStyleClass="gx-buttonStyleClass"
                isShowButton={false}
                width={750}
                className="doctor-profile-modal"
                extraFieldName="profileImageUrl"
                extraFieldValue={photoList.imageUrl}
                extraFieldAddress="pharmacyFullAddress"
                extraFieldValueAddress = {selectedAddress}
                recordName="pharmacyList"
                

            >
                <PharmacyForm
                    setPhotoState={setPhotoState}
                    visibility={Add_Pharmacy_Modal}
                    photoList={photoList}
                    SelectedAddress={SelectedAddress}
                />
            </FormModal>
        </div>

    )
}

export default AddNewPharmacy
