//generalAnalytics/components/RevenueOverview

import React, { useEffect } from 'react'
import RevenuePieChart from "./RevenuePieChart"
import Widget from 'components/Widget'

import { DataGetAction } from 'redux/actions/CommonHttp'
import { useDispatch, useSelector } from 'react-redux'
import IntlMessages from "util/IntlMessages"
const RevenueOverview = () => {

//     const revenueOverview = [
//         {
//         categoryName : 'Baby',
//         value : 1325
//     },
//     {
//         categoryName : 'Medicine',
//         value : 350
//     },
//     {
//         categoryName : 'Cosmetics',
//         value : 220
//     },
//     {
//         categoryName : 'pregnancy',
//         value : 100
//     },
// ]

    const dispatch = useDispatch();
    const adminProps = useSelector(state => state.adminDashboard)

    const { revenueOverview } = adminProps




    const fetchRevenueOverview = () => {
        dispatch(DataGetAction('getRevenueDashboard', 'getRevenueDashboard', '', 'gettingRevenueDashboard'));
    }
    useEffect(fetchRevenueOverview, [])

    const COLORS = ['#C39FDF', '#3FD4A2', '#0D7DD9', '#5797FC'];

    const renderRevenueOverview = () => {

        return revenueOverview && revenueOverview.map((item,index) => {
            return <div className="gx-d-flex  gx-mb-3">
                <span className="gx-font-gilory-medium gx-mr-2" style={{ borderBottom: `3px solid ${COLORS[index]}` }}>{item.categoryName}</span>
                <span >{item.totalProduct}</span>
            </div>
        })
    }

    return (

        <Widget styleName={'revenueOverview'} title={  <h4 className="gx-blue-font gx-font-sans-bold gx-text-capitalize"><IntlMessages id="dashboard.revenueOverview" /> </h4>}>
            <div className="gx-d-flex gx-justify-content-between">
                <div className="gx-d-flex gx-justify-content-between gx-align-items-center gx-w-100">
                    <div className="gx-mr-3 gx-w-75">
                        
                        {renderRevenueOverview()}
                    </div>
                    <RevenuePieChart revenueOverview={revenueOverview && revenueOverview} />
                </div>
            </div>



        </Widget>
    )
}

export default RevenueOverview
