const SocketResponseState = require('../redux/actions/socket').SocketResponseState;
const io = require("socket.io-client");
const Socket = (function () {
    let instance;
    function createInstance(userId) {
        const socket = io(process.env.REACT_APP_ROOT_URL, { query: `clientId=${userId}&name=admin`  });
        socket.on("connect", function (err, data) {

        });

        
        socket.on("newActivityCreated", function (data) {

            SocketResponseState('newActivityCreated', data)
        })
        socket.on("addNewProduct", function (data) {

            SocketResponseState('AddRecord', { Record: data, message : 'New Product Added Successfully!' }, 'pendingProductList');
            // SocketResponseState('newActivityCreated', data)
        })
        socket.on("editProduct", function (data) {

            SocketResponseState('EditRecord', { Record: data, message : `Product ${data.productName && data.productName} has been Updated Successfully!`}, 'productList',);
        })

        socket.on("NewLeadReceived", function (data) {
            SocketResponseState('NewLeadReceived', data)
        })
       
        function onConnect(userId) {
            return new Promise((resolve, reject) => {
                socket.emit("connect", { "id": userId });
                resolve();
            });
        }
        function socketDisconnet() {
            return new Promise((resolve, reject) => {
                socket.emit("disconnect", {});
                resolve();
            });
        }
        return {
            onConnect,
            socketDisconnet,
        };
    }
    return {
        getInstance: function (userId) {
            if (!instance) {
                instance = createInstance(userId);
            }
            return instance;
        }
    };
})();
module.exports = {
    Socket: Socket
};